import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const GoalHoursStep = ({ onNext, onPrevious, goalData }) => {
  const [selectedHours, setSelectedHours] = useState(goalData.hours ? goalData.hours.toString() : '');
  const hourOptions = [...Array(40).keys()].map(i => (i + 1).toString());

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext({ hours: parseInt(selectedHours, 10) });
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Time Commitment</h2>
        <p className="mt-2 text-gray-600">Choose how much time you can realistically dedicate each week.</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex items-center mb-3">
            <FontAwesomeIcon icon={faClock} className="text-blue-500 mr-2" />
            <label 
              htmlFor="hours" 
              className="text-sm font-medium text-blue-600 uppercase tracking-wider"
            >
              Weekly Time Investment
            </label>
          </div>
          
          <div className="relative">
            <select
              id="hours"
              name="hours"
              value={selectedHours}
              onChange={(e) => setSelectedHours(e.target.value)}
              className={`
                w-full px-4 py-3 text-gray-700 bg-white border border-gray-200 rounded-lg 
                appearance-none focus:ring-2 focus:ring-blue-500 focus:border-transparent 
                transition-all duration-200 pr-10 hover:border-blue-300
                ${!selectedHours ? 'text-gray-500' : 'text-gray-700'}
              `}
              required
            >
              <option value="">Select hours per week</option>
              {hourOptions.map((option) => (
                <option key={option} value={option}>
                  {option} {parseInt(option, 10) === 1 ? 'hour' : 'hours'}
                </option>
              ))}
            </select>
            
            <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
              <svg 
                className="w-5 h-5 text-gray-400" 
                xmlns="http://www.w3.org/2000/svg" 
                viewBox="0 0 20 20" 
                fill="currentColor"
              >
                <path 
                  fillRule="evenodd" 
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" 
                  clipRule="evenodd" 
                />
              </svg>
            </div>
          </div>

          <p className="mt-2 text-sm text-gray-500">
            Choose a realistic amount of time you can commit each week
          </p>
        </div>

        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <button
            type="button"
            onClick={onPrevious}
            className="group w-full sm:w-auto px-6 py-3 text-sm font-semibold text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-all duration-200 transform hover:-translate-x-0.5"
          >
            <FontAwesomeIcon 
              icon={faArrowLeft} 
              className="mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" 
            />
            Previous
          </button>
          <button 
            type="submit" 
            disabled={!selectedHours}
            className={`
              group w-full sm:w-auto px-6 py-3 text-sm font-semibold text-white rounded-lg 
              transition-all duration-200 transform hover:translate-x-0.5
              ${selectedHours 
                ? 'bg-blue-500 hover:bg-blue-600' 
                : 'bg-blue-400 cursor-not-allowed'}
            `}
          >
            Next
            <FontAwesomeIcon 
              icon={faArrowRight} 
              className="ml-2 transform group-hover:translate-x-1 transition-transform duration-200" 
            />
          </button>
        </div>
      </form>
    </div>
  );
};

export default GoalHoursStep;