import React, { useEffect, useState, useCallback } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { useAuth } from '../AuthContext';
import { useStripeCheckout } from '../utils/stripeCheckout';
import { 
  getGoalById, 
  deleteGoal, 
  updateGoalNotes, 
  getMentorFeedback,
  getMentorInteractions,
  updateMentorInteractions,
  updateCompletedSubtasks, 
  updateActionableTasks 
} from '../api';
import GoalHeader from '../components/goalDetails/GoalHeader';
import KeyMetrics from '../components/goalDetails/KeyMetrics';
import SmartDetails from '../components/goalDetails/SmartDetails';
import GamePlan from '../components/goalDetails/GamePlan';
import HelpfulResources from '../components/goalDetails/HelpfulResources';
import ActionableTasks from '../components/goalDetails/ActionableTasks';
import AIMentorChat from '../components/goalDetails/AIMentorChat';
import LoadingState from '../components/goalDetails/LoadingState';
import ErrorState from '../components/goalDetails/ErrorState';
import Notes from '../components/goalDetails/Notes';
import UpgradeModal from '../components/goalDetails/UpgradeModal';
import DeleteConfirmationModal from '../components/goalDetails/DeleteConfirmationModal';
import GoalCheckInPreferences from '../components/goalDetails/GoalCheckInPreferences';

const GoalDetailsPage = () => {
  const [goal, setGoal] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notes, setNotes] = useState([]);
  const [mentorInput, setMentorInput] = useState('');
  const [mentorResponse, setMentorResponse] = useState(null);
  const [isMentorResponding, setIsMentorResponding] = useState(false);
  const [completedSubtasks, setCompletedSubtasks] = useState([]);
  const [showUpdateConfirmation, setShowUpdateConfirmation] = useState(false);
  const [isUpdatingTasks, setIsUpdatingTasks] = useState(false);
  const [updatingTaskIndex, setUpdatingTaskIndex] = useState(null);
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);
  const [upgradeTrigger, setUpgradeTrigger] = useState('');
  const [isPremium, setIsPremium] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [dailyMentorLimit, setDailyMentorLimit] = useState({
    count: 0,
    maxCount: 3,
    remainingCount: 3
  });
  const MAX_MENTOR_INPUT_LENGTH = 280;

  // Hooks
  const { id } = useParams();
  const navigate = useNavigate();
  const { user, isLoading: userLoading, fetchUser } = useAuth();
  const { handleUpgrade: stripeHandleUpgrade } = useStripeCheckout(user?.id, user?.email);
  const [upgradeLoading, setUpgradeLoading] = useState(false);

  // Check premium status when user changes
  useEffect(() => {
    if (user) {
      setIsPremium(user.planTier === 'Premium');
    }
  }, [user]);

  // Initial goal data fetch
  useEffect(() => {
    const fetchGoal = async () => {
      try {
        setLoading(true);
        const fetchedGoal = await getGoalById(id);
        if (!fetchedGoal) {
          setError('Goal not found');
        } else {
          setGoal(fetchedGoal);
          setNotes(fetchedGoal.notes || []);
          setCompletedSubtasks(fetchedGoal.completedSubtasks || []);
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchGoal();
  }, [id]);

  useEffect(() => {
    const fetchMentorLimit = async () => {
      // Only fetch if not premium and have a user
      if (!user?.id || isPremium) {
        setDailyMentorLimit({
          count: 0,
          maxCount: 3,
          remainingCount: 3
        });
        return;
      }
      
      try {
        const data = await getMentorInteractions();
        
        const currentCount = data.dailyCount || 0;
        setDailyMentorLimit({
          count: currentCount,
          maxCount: 3,
          remainingCount: Math.max(0, 3 - currentCount)
        });
      } catch (error) {
        // Set default values on error instead of null
        setDailyMentorLimit({
          count: 0,
          maxCount: 3,
          remainingCount: 3
        });
      }
    };
  
    fetchMentorLimit();
  }, [user, isPremium]);

  // Subtask handlers
  const handleSubtaskClick = async (phaseIndex, taskIndex, subtaskIndex) => {
    try {
      const updatedGoal = await updateCompletedSubtasks(id, {
        phaseIndex,
        taskIndex,
        subtaskIndex
      });
      setCompletedSubtasks(updatedGoal.completedSubtasks);
      setShowUpdateConfirmation(true);
    } catch (error) {
      setError('Failed to update task status');
    }
  };

  const handleUpdateTasks = async () => {
    try {
      setIsUpdatingTasks(true);
      const updatedGoal = await updateActionableTasks(id);
      setGoal(updatedGoal);
      setShowUpdateConfirmation(false);
    } catch (error) {
      setError('Failed to update tasks');
    } finally {
      setIsUpdatingTasks(false);
    }
  };

  const handleRefreshTasks = async (taskIndex) => {
    try {
      setUpdatingTaskIndex(taskIndex);
      const updatedGoal = await updateActionableTasks(id, taskIndex);
      setGoal(updatedGoal);
    } catch (error) {
      setError('Failed to update task');
    } finally {
      setUpdatingTaskIndex(null);
    }
  };

  // Notes Handlers

  const saveNotes = useCallback(async (updatedNotes) => {
    try {
      await updateGoalNotes(id, updatedNotes);
      return true;
    } catch (error) {
      return false;
    }
  }, [id]);

  const handleAddNote = useCallback(async (newNoteContent) => {
    if (!isPremium && notes.length >= 3) {
      setUpgradeTrigger('notes');
      setShowUpgradeModal(true);
      return;
    }
    const newNote = {
      id: Date.now(),
      content: newNoteContent
    };
    const updatedNotes = [...notes, newNote];
    setNotes(updatedNotes);
    await saveNotes(updatedNotes);
  }, [isPremium, notes, saveNotes]);

  const handleEditNote = async (noteId, newContent) => {
    const updatedNotes = notes.map(note =>
      note.id === noteId ? { ...note, content: newContent } : note
    );
    setNotes(updatedNotes);
    await saveNotes(updatedNotes);
  };

  const handleDeleteNote = async (noteId) => {
    const updatedNotes = notes.filter(note => note.id !== noteId);
    setNotes(updatedNotes);
    await saveNotes(updatedNotes);
  };

  // Goal Handlers 

  const confirmDeleteGoal = async () => {
    try {
      const response = await deleteGoal(id);
      setShowDeleteConfirmation(false);
      navigate('/dashboard');
    } catch (error) {
      const errorMessage = error.response?.data?.message || error.message || 'An unknown error occurred';
      setError(`Failed to delete goal: ${errorMessage}`);
      setShowDeleteConfirmation(false);
    }
  };

  const handleUpgrade = useCallback(async () => {
    try {
      setUpgradeLoading(true);
      setError('');
  
      // Debug log
  
      // Validate user data is present
      if (!user?.id || !user?.email) {
        throw new Error('Required user information is missing. Please try logging in again.');
      }
  
      await stripeHandleUpgrade();
      
      // After successful redirect to Stripe, these won't execute immediately
      await fetchUser(); // Refresh user data
      setShowUpgradeModal(false);
      setUpgradeTrigger('');
      setIsPremium(true);
  
      // Refresh goal data
      const updatedGoal = await getGoalById(id);
      setGoal(updatedGoal);
      setNotes(updatedGoal.notes || []);
    } catch (error) {
      setError(error.message || 'Failed to initiate upgrade. Please try again.');
    } finally {
      setUpgradeLoading(false);
    }
  }, [stripeHandleUpgrade, fetchUser, user, id]);

  useEffect(() => {
  }, [user, userLoading]);

  // Mentor Interaction
  const handleMentorInteraction = useCallback(async () => {
    if (!mentorInput.trim() || mentorInput.length > MAX_MENTOR_INPUT_LENGTH) return;
    
    if (!user?.id) {
      setError("You must be logged in to use this feature.");
      return;
    }
  
    // Safe check for count
    if (!isPremium && (dailyMentorLimit?.count ?? 0) >= 3) {
      setUpgradeTrigger('mentor');
      setShowUpgradeModal(true);
      return;
    }
  
    setIsMentorResponding(true);
    try {
      if (!isPremium) {
        try {
          const updateData = await updateMentorInteractions();
          const currentCount = updateData.dailyCount || 0;
          setDailyMentorLimit({
            count: currentCount,
            maxCount: 3,
            remainingCount: Math.max(0, 3 - currentCount)
          });
        } catch (error) {
          if (error.response?.data?.error === 'Daily limit reached') {
            setUpgradeTrigger('mentor');
            setShowUpgradeModal(true);
            setIsMentorResponding(false);
            return;
          }
          throw error;
        }
      }
  
      const response = await getMentorFeedback(goal._id, mentorInput);
      setMentorResponse(response);
    } catch (error) {
      setMentorResponse({
        error: true,
        message: "I'm sorry, I couldn't process your request at this time. Please try again later.",
        additionalInfo: error.message
      });
    } finally {
      setIsMentorResponding(false);
      setMentorInput('');
    }
  }, [
    mentorInput,
    user,
    isPremium,
    dailyMentorLimit.count,
    goal,
    setMentorInput,
    setMentorResponse,
    setIsMentorResponding,
    setError,
    setUpgradeTrigger,
    setShowUpgradeModal,
    setDailyMentorLimit
  ]);

  // Progress calculation
  const calculateProgress = useCallback(() => {
    if (!goal) return 0;
    
    const totalSubtasks = goal.gamePlan.phases.reduce(
      (total, phase) =>
        total + phase.tasks.reduce((phaseTotal, task) => phaseTotal + (task.subtasks?.length || 0), 0),
      0
    );
    const completedSubtasksCount = completedSubtasks.length;

    return totalSubtasks === 0 ? 0 : Math.round((completedSubtasksCount / totalSubtasks) * 100);
  }, [goal, completedSubtasks]);


  // Loading state
  if (loading || userLoading) {
    return <LoadingState />;
  }

  // Error state
  if (error) {
    return <ErrorState message={error} />;
  }

  // No goal found state
  if (!goal) {
    return <ErrorState message="The requested goal could not be found." />;
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-8 md:pt-16 pb-24 px-4 sm:px-6 lg:px-8">
      <div className="max-w-7xl mx-auto">
        <GoalHeader 
          goal={goal}
          onDeleteClick={() => setShowDeleteConfirmation(true)}
          progress={calculateProgress()}
        />

        <KeyMetrics 
          goal={goal}
          completedSubtasks={completedSubtasks}
        />

        <SmartDetails goal={goal} />

        {/* Game Plan Section */}
        <GamePlan
          goal={goal}
          completedSubtasks={completedSubtasks}
          onSubtaskToggle={handleSubtaskClick}
          onUpdateTasks={handleUpdateTasks}
          isUpdating={isUpdatingTasks}
        />

        {/* Actionable Tasks Section */}
        <ActionableTasks
          tasks={goal.actionableTasks}
          onRefreshTask={handleRefreshTasks}
          updatingTaskIndex={updatingTaskIndex}
        />

        <HelpfulResources resources={goal.resources} />

        {/* Update Confirmation Modal */}
        {showUpdateConfirmation && (
          <div className="fixed z-10 inset-0 overflow-y-auto">
            <div className="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <div className="fixed inset-0 transition-opacity" aria-hidden="true">
                <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
              </div>

              <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
                &#8203;
              </span>

              <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
                <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full sm:mx-0 sm:h-10 sm:w-10">
                      <span className="text-3xl" role="img" aria-label="Trophy">🏆</span>
                    </div>
                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                      <h3 className="text-lg leading-6 font-medium text-gray-900">
                        You've made progress on your goal!
                      </h3>
                      <div className="mt-2">
                        <p className="text-sm text-gray-500">
                          Do you want to update your actionable tasks based on your recent progress?
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={handleUpdateTasks}
                    disabled={isUpdatingTasks}
                  >
                    {isUpdatingTasks ? (
                      <>
                        <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                        Updating...
                      </>
                    ) : (
                      'Update Tasks'
                    )}
                  </button>
                  <button
                    type="button"
                    className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                    onClick={() => setShowUpdateConfirmation(false)}
                  >
                    Maybe Later
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}

        <AIMentorChat
          goal={goal}
          mentorInput={mentorInput}
          setMentorInput={setMentorInput}
          mentorResponse={mentorResponse}
          isMentorResponding={isMentorResponding}
          onSendMessage={handleMentorInteraction}
          isPremium={isPremium}
          remainingInteractions={dailyMentorLimit.remainingCount}
          maxInputLength={MAX_MENTOR_INPUT_LENGTH}
        />

        <Notes 
          notes={notes}
          onAddNote={handleAddNote}
          onEditNote={handleEditNote}
          onDeleteNote={handleDeleteNote}
          isPremium={isPremium}
        />

        <GoalCheckInPreferences 
          goalId={id} 
          isPremium={isPremium} 
          onUpgrade={handleUpgrade}
        />

        <UpgradeModal
          isOpen={showUpgradeModal}
          onClose={() => {
            setShowUpgradeModal(false);
            setError('');
          }}
          onUpgrade={handleUpgrade}
          triggerFeature={upgradeTrigger}
          isLoading={upgradeLoading}
        />
        

        <DeleteConfirmationModal
          isOpen={showDeleteConfirmation}
          onClose={() => setShowDeleteConfirmation(false)}
          onConfirm={confirmDeleteGoal}
        />

      </div>
    </div>
  );
};

export default GoalDetailsPage;