import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { isAuthenticated } from '../utils/auth';
import { useAuth } from '../AuthContext';

const PrivateRoute = ({ children }) => {
  const location = useLocation();
  const { user } = useAuth();

  // First check authentication
  if (!isAuthenticated()) {
    return <Navigate to="/login" replace />;
  }

  // Then check onboarding status
  if (user && !user.onboardingCompleted && location.pathname !== '/onboarding') {
    return <Navigate to="/onboarding" replace />;
  }

  // Prevent accessing onboarding if already completed
  if (user?.onboardingCompleted && location.pathname === '/onboarding') {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

export default PrivateRoute;