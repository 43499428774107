import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheckCircle, 
  faRocket, 
  faChartLine,
  faLightbulb,
  faListCheck,
  faStar,
  faArrowRight
} from '@fortawesome/free-solid-svg-icons';

const SuccessPage = () => {
  const features = [
    {
      title: 'Advanced AI Plans',
      description: 'Get detailed, personalized achievement plans powered by AI',
      icon: faRocket,
      color: 'text-purple-500'
    },
    {
      title: 'Unlimited Mentoring',
      description: 'Access unlimited AI mentor feedback and guidance',
      icon: faCheckCircle,
      color: 'text-blue-500'
    },
    {
      title: 'Progress Tracking',
      description: 'Track unlimited thoughts, ideas, and milestones',
      icon: faChartLine,
      color: 'text-green-500'
    },
    {
      title: 'Smart Recommendations',
      description: 'Receive fully personalized recommendations',
      icon: faLightbulb,
      color: 'text-yellow-500'
    },
    {
      title: 'Dynamic Tasks',
      description: 'Get adaptive task lists that evolve with your progress',
      icon: faListCheck,
      color: 'text-indigo-500'
    },
    {
      title: 'Premium Support',
      description: 'Enjoy priority support when you need help',
      icon: faStar,
      color: 'text-orange-500'
    }
  ];

  return (
    <div className="min-h-screen bg-gradient-to-b from-blue-50 to-white py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        <div className="bg-white rounded-2xl shadow-xl overflow-hidden transform transition-all duration-500 hover:shadow-2xl">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-500 to-blue-600 px-6 py-12 sm:px-10 text-center relative overflow-hidden">
            {/* Animated success circle */}
            <div className="mb-8 relative">
              <div className="absolute inset-0 animate-ping bg-white/20 w-20 h-20 rounded-full mx-auto" />
              <div className="relative bg-white/20 w-20 h-20 rounded-full mx-auto flex items-center justify-center backdrop-blur-sm animate-bounce">
                <FontAwesomeIcon 
                  icon={faCheckCircle} 
                  className="w-12 h-12 text-white" 
                />
              </div>
            </div>
            
            {/* Background decoration */}
            <div className="absolute top-0 left-0 right-0 bottom-0 overflow-hidden pointer-events-none">
              <div className="absolute -right-10 -top-10 w-40 h-40 bg-white/10 rounded-full" />
              <div className="absolute -left-10 -bottom-10 w-40 h-40 bg-white/10 rounded-full" />
            </div>

            <h1 className="text-4xl font-bold text-white mb-4 relative">
              Welcome to Premium!
            </h1>
            <p className="text-blue-100 text-xl max-w-2xl mx-auto relative">
              Your account has been successfully upgraded. Let's achieve greatness together!
            </p>
          </div>

          {/* Features Grid */}
          <div className="px-6 py-12 sm:px-10">
            <h2 className="text-2xl font-bold text-gray-900 mb-8 text-center">
              Your Premium Features
            </h2>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              {features.map((feature, index) => (
                <div 
                  key={index}
                  className="group bg-gray-50 rounded-xl p-6 hover:bg-white hover:shadow-md transition-all duration-300 transform hover:-translate-y-1"
                >
                  <div className="flex items-start space-x-4">
                    <div className={`${feature.color} p-3 bg-white rounded-lg shadow-sm group-hover:scale-110 transition-transform duration-300`}>
                      <FontAwesomeIcon icon={feature.icon} className="w-6 h-6" />
                    </div>
                    <div>
                      <h3 className="font-semibold text-gray-900 mb-1">
                        {feature.title}
                      </h3>
                      <p className="text-gray-600 text-sm">
                        {feature.description}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {/* Motivational Message */}
          <div className="px-6 py-12 sm:px-10 bg-gradient-to-b from-white to-gray-50 border-t border-gray-100">
            <div className="text-center max-w-2xl mx-auto">
              <p className="text-2xl text-gray-800 font-bold mb-8">
                Ready to crush those goals? Let's get started!
              </p>
              <div className="flex flex-col sm:flex-row justify-center gap-4">
                <Link
                  to="/dashboard"
                  className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-lg text-white bg-blue-500 hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                >
                  Go to Dashboard
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </Link>
                <Link
                  to="/create-goal"
                  className="inline-flex items-center justify-center px-8 py-4 text-lg font-medium rounded-lg text-white bg-green-500 hover:bg-green-600 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                >
                  Create a New Goal
                  <FontAwesomeIcon icon={faArrowRight} className="ml-2" />
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SuccessPage;