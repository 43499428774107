import React from 'react';
import GoalStepBase from './GoalStepBase';

const GoalMeasurableStep = ({ onNext, onPrevious, goalData, setGoalData }) => (
  <GoalStepBase
    title="Make it Measurable"
    description="Define concrete criteria for measuring progress toward your goal."
    fieldName="measurable"
    value={goalData.measurable}
    onChange={(value) => setGoalData({ ...goalData, measurable: value })}
    onNext={onNext}
    onPrevious={onPrevious}
    placeholder="ex. When I release a song on Spotify."
    labelText="How will you measure success?"
  />
);

export default GoalMeasurableStep;