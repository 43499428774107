export const trackEvent = (eventName, properties = {}) => {
    if (window.gtag) {
      window.gtag('event', eventName, {
        ...properties,
        timestamp: new Date().toISOString()
      });
    }
  };
  
  export const analyticsEvents = {
    // Auth Events
    SIGN_UP: 'sign_up',
    LOGIN: 'login',
    LOGOUT: 'logout',
    
    // Premium Events
    VIEW_PRICING: 'view_pricing',
    START_CHECKOUT: 'begin_checkout',
    COMPLETE_CHECKOUT: 'purchase',
    
    // Goal Events
    CREATE_GOAL: 'create_goal',
    UPDATE_GOAL: 'update_goal',
    COMPLETE_GOAL: 'complete_goal',
    
    // Check-in Events
    ENABLE_CHECKINS: 'enable_checkins',
    COMPLETE_CHECKIN: 'complete_checkin'
  };