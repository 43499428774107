import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faLightbulb, 
  faExternalLinkAlt, 
} from '@fortawesome/free-solid-svg-icons';  

const HelpfulResources = ({ resources }) => {
    if (!resources || resources.length === 0) return null;
  
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <FontAwesomeIcon icon={faLightbulb} className="text-blue-500 mr-3" />
          Helpful Resources
        </h2>
        
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {resources.map((resource, index) => (
            <a
              key={index}
              href={resource.url}
              target="_blank"
              rel="noopener noreferrer"
              className="group p-4 bg-gray-50 rounded-lg hover:bg-blue-50 transition-all duration-300"
            >
              <div className="flex items-start">
                <div className="flex-1">
                  <h3 className="font-semibold text-gray-900 group-hover:text-blue-600 transition-colors duration-200">
                    {resource.title}
                  </h3>
                  {resource.description && (
                    <p className="text-sm text-gray-600 mt-1">{resource.description}</p>
                  )}
                </div>
                <FontAwesomeIcon 
                  icon={faExternalLinkAlt} 
                  className="ml-2 text-gray-400 group-hover:text-blue-500 transform group-hover:translate-x-1 group-hover:-translate-y-1 transition-transform duration-200" 
                />
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  };

export default HelpfulResources;