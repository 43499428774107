import React, { useState, useEffect } from 'react';
import { useAuth } from '../AuthContext';
import api from '../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faUser, 
  faEnvelope, 
  faPhone, 
  faChartBar, 
  faBriefcase, 
  faGraduationCap,
  faEdit,
  faSave,
  faTimes,
  faCheck,
  faExclamationCircle
} from '@fortawesome/free-solid-svg-icons';
import { useStripeCheckout } from '../utils/stripeCheckout';

const AccountDetailsPage = () => {
  const { updateUser, user } = useAuth();
  const [editMode, setEditMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    ageRange: '',
    occupation: '',
    education: '',
    planTier: '',
  });

  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [upgradeLoading, setUpgradeLoading] = useState(false);

  const userId = user?.id || user?._id || userData?._id;
  const userEmail = user?.email || userData?.email;

  const { handleUpgrade, handleCancelSubscription } = useStripeCheckout(userId, userEmail);
  const [cancelLoading, setCancelLoading] = useState(false);

  // Validation patterns
  const validationPatterns = {
    name: /^[a-zA-Z\s'-]{2,20}$/,
    email: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    phoneNumber: /^\+?[1-9]\d{1,14}$/,
    occupation: /^[a-zA-Z0-9\s\-&().]{2,30}$/ 
  };

  // Validation messages
  const validationMessages = {
    name: 'Name should be 2-20 characters long and contain only letters, hyphens, and apostrophes',
    email: 'Please enter a valid email address',
    phoneNumber: 'Please enter a valid phone number',
    occupation: 'Occupation should be 2-30 characters long and contain only letters, spaces, and basic punctuation'
  };

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await api.get('/users/me');
        // Log the response to see the structure
        
        const sanitizedData = sanitizeUserData(response.data);
        setUserData(sanitizedData);
        setLoading(false);
      } catch (error) {
        setError('Failed to load user data. Please try again.');
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  // Sanitize user data
  const sanitizeUserData = (data) => {
    return {
      ...data,
      name: data.name ? data.name.trim() : '',
      email: data.email ? data.email.trim().toLowerCase() : '',
      phoneNumber: data.phoneNumber ? data.phoneNumber.trim() : '',
      occupation: data.occupation ? data.occupation.replace(/\s+/g, ' ').trim() : '',
    };
  };

  // Validate individual field
  const validateField = (name, value) => {
    if (!value && name !== 'phoneNumber') return 'This field is required';
    if (validationPatterns[name] && !validationPatterns[name].test(value)) {
      return validationMessages[name];
    }
    return '';
  };

  // Validate all fields
  const validateForm = () => {
    const errors = {};
    const requiredFields = ['name', 'email'];

    requiredFields.forEach(field => {
      const error = validateField(field, userData[field]);
      if (error) errors[field] = error;
    });

    // Validate optional fields if they have values
    if (userData.phoneNumber) {
      const phoneError = validateField('phoneNumber', userData.phoneNumber);
      if (phoneError) errors.phoneNumber = phoneError;
    }

    if (userData.occupation) {
      const occupationError = validateField('occupation', userData.occupation);
      if (occupationError) errors.occupation = occupationError;
    }

    return errors;
  };

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    const newValue = type === 'checkbox' ? checked : value;

    // Real-time validation for text inputs
    if (type !== 'checkbox') {
      const error = validateField(name, newValue);
      setFormErrors(prev => ({
        ...prev,
        [name]: error
      }));
    }

    setUserData(prev => ({
      ...prev,
      [name]: newValue
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    
    // Validate all fields before submission
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setFormErrors(errors);
      setError('Please correct the errors before submitting.');
      return;
    }

    try {
      // Sanitize data before sending to server
      const sanitizedData = sanitizeUserData(userData);
      
      const response = await api.put('/users/update', sanitizedData);
      
      // Validate server response before updating state
      if (response.data && response.data.user) {
        updateUser(response.data.user);
        setSuccess('Account details updated successfully!');
        setEditMode(false);
        setUserData(prevData => ({
          ...prevData,
          ...sanitizeUserData(response.data.user)
        }));
      } else {
        throw new Error('Invalid server response');
      }
    } catch (error) {
      setError(error.response?.data?.message || 'Failed to update account details. Please try again.');
    }
  };

  const handleUpgradeClick = async () => {
    try {
      setError('');
      setUpgradeLoading(true);

      // Validate user data
      if (!userId) {
        throw new Error('User ID is missing. Please ensure you are properly logged in.');
      }

      if (!userEmail) {
        throw new Error('User email is missing. Please ensure you are properly logged in.');
      }

      await handleUpgrade();
    } catch (error) {
      setError(error.message || 'Failed to initiate upgrade. Please try again.');
    } finally {
      setUpgradeLoading(false);
    }
  };

  const handleCancelSubscriptionClick = async () => {
    try {
      setCancelLoading(true);
      setError('');
      setSuccess('');
  
      // Validate user has an active subscription
      if (!userId) {
        throw new Error('User ID is missing. Please try logging in again.');
      }
  
      if (userData.planTier !== 'Premium') {
        throw new Error('No active subscription found.');
      }
  
      
      // Call the cancellation endpoint
      await handleCancelSubscription();
  
      // Refresh user data
      const response = await api.get('/users/me');
      const updatedUserData = sanitizeUserData(response.data);
      setUserData(updatedUserData);
      updateUser(response.data); // Update auth context
  
      setSuccess('Your subscription has been cancelled successfully. You will have access to premium features until the end of your current billing period.');
    } catch (error) {
      setError(error.message || 'Failed to cancel subscription. Please try again or contact support.');
    } finally {
      setCancelLoading(false);
    }
  };

  const handleEditClick = (e) => {
    e.preventDefault();
    setError('');
    setSuccess('');
    setFormErrors({});
    setEditMode(true);
  };

  const handleCancelEdit = () => {
    setEditMode(false);
    setError('');
    setSuccess('');
    setFormErrors({});
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50 pt-12 md:pt-20 pb-28 px-4 sm:px-6 lg:px-8">
      <div className="max-w-4xl mx-auto">
        {/* Header */}
        <div className="mb-8 text-center">
          <h1 className="text-3xl font-bold text-gray-900">Account Settings</h1>
          <p className="mt-2 text-gray-600">Manage your account details and preferences</p>
        </div>

        {/* Main Card */}
        <div className="bg-white rounded-2xl shadow-lg overflow-hidden">
          {/* Account Status Banner */}
          <div className={`px-6 py-4 ${userData.planTier === 'Premium' ? 'bg-blue-500' : 'bg-gray-500'}`}>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <div className="flex-shrink-0">
                  <svg className="h-8 w-8 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" 
                      d={userData.planTier === 'Premium' 
                        ? "M9 12l2 2 4-4M7.835 4.697a3.42 3.42 0 001.946-.806 3.42 3.42 0 014.438 0 3.42 3.42 0 001.946.806 3.42 3.42 0 013.138 3.138 3.42 3.42 0 00.806 1.946 3.42 3.42 0 010 4.438 3.42 3.42 0 00-.806 1.946 3.42 3.42 0 01-3.138 3.138 3.42 3.42 0 00-1.946.806 3.42 3.42 0 01-4.438 0 3.42 3.42 0 00-1.946-.806 3.42 3.42 0 01-3.138-3.138 3.42 3.42 0 00-.806-1.946 3.42 3.42 0 010-4.438 3.42 3.42 0 00.806-1.946 3.42 3.42 0 013.138-3.138z"
                        : "M13 10V3L4 14h7v7l9-11h-7z"} 
                    />
                  </svg>
                </div>
                <div className="ml-3">
                  <h2 className="text-lg font-medium text-white">
                    {userData.planTier === 'Premium' ? 'Premium Plan' : 'Free Plan'}
                  </h2>
                  <p className="hidden md:block text-sm text-white opacity-90">
                    {userData.planTier === 'Premium' 
                      ? 'You have complete access!' 
                      : 'Upgrade to unlock all features!'}
                  </p>
                </div>
              </div>
              {userData.planTier === 'Free' ? (
                <button
                  onClick={handleUpgradeClick}
                  disabled={upgradeLoading}
                  className={`px-4 py-2 bg-white text-gray-800 rounded-lg font-medium transition-colors duration-200
                    ${upgradeLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-gray-100'}`}
                >
                  {upgradeLoading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-gray-800" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Processing...
                    </span>
                  ) : (
                    'Upgrade Now'
                  )}
                </button>
              ) : (
                <button
                  onClick={handleCancelSubscriptionClick}
                  disabled={cancelLoading}
                  className={`px-4 py-2 bg-white text-red-500 rounded-lg font-medium 
                    ${cancelLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-red-50'} 
                    transition-colors duration-200`}
                >
                  {cancelLoading ? (
                    <span className="flex items-center">
                      <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-red-500" fill="none" viewBox="0 0 24 24">
                        <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                        <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                      </svg>
                      Cancelling...
                    </span>
                  ) : (
                    'Cancel Subscription'
                  )}
                </button>
              )}
            </div>
          </div>

          {/* Form Content */}
          <div className="p-6">
            {success && (
              <div className="mb-6 bg-green-50 border border-green-200 text-green-600 px-4 py-3 rounded-lg flex items-center">
                <FontAwesomeIcon icon={faCheck} className="h-5 w-5 mr-2" />
                {success}
              </div>
            )}

            {error && (
              <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg flex items-center">
                <FontAwesomeIcon icon={faExclamationCircle} className="h-5 w-5 mr-2" />
                {error}
              </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                <InputField
                  label="Name"
                  name="name"
                  value={userData.name}
                  onChange={handleChange}
                  disabled={!editMode}
                  icon={<FontAwesomeIcon icon={faUser} className="text-gray-400" />}
                  error={formErrors.name}
                  required
                />
                <InputField
                  label="Email"
                  name="email"
                  type="email"
                  value={userData.email}
                  onChange={handleChange}
                  disabled={!editMode}
                  icon={<FontAwesomeIcon icon={faEnvelope} className="text-gray-400" />}
                  error={formErrors.email}
                  required
                />
                <InputField
                  label="Phone Number"
                  name="phoneNumber"
                  type="tel"
                  value={userData.phoneNumber}
                  onChange={handleChange}
                  disabled={!editMode}
                  icon={<FontAwesomeIcon icon={faPhone} className="text-gray-400" />}
                  error={formErrors.phoneNumber}
                />
                <InputField
                  label="Age Range"
                  name="ageRange"
                  value={userData.ageRange}
                  disabled={true}
                  icon={<FontAwesomeIcon icon={faChartBar} className="text-gray-400" />}
                />
                <InputField
                  label="Occupation"
                  name="occupation"
                  value={userData.occupation}
                  onChange={handleChange}
                  disabled={!editMode}
                  icon={<FontAwesomeIcon icon={faBriefcase} className="text-gray-400" />}
                  error={formErrors.occupation}
                />
                <InputField
                  label="Education"
                  name="education"
                  value={userData.education}
                  disabled={true}
                  icon={<FontAwesomeIcon icon={faGraduationCap} className="text-gray-400" />}
                />
              </div>

              <div className="flex justify-end space-x-4 pt-6">
                {editMode ? (
                  <>
                    <button
                      type="submit"
                      className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
                    >
                      <FontAwesomeIcon icon={faSave} className="mr-2" /> Save Changes
                    </button>
                    <button
                      type="button"
                      onClick={handleCancelEdit}
                      className="inline-flex items-center px-6 py-3 bg-gray-100 text-gray-700 rounded-lg font-medium hover:bg-gray-200 focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200"
                    >
                      <FontAwesomeIcon icon={faTimes} className="mr-2" /> Cancel
                    </button>
                  </>
                ) : (
                  <button
                    type="button"
                    onClick={handleEditClick}
                    className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg font-medium hover:bg-blue-600 focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200"
                  >
                    <FontAwesomeIcon icon={faEdit} className="mr-2" /> Edit Details
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const InputField = ({ 
  label, 
  name, 
  type = 'text', 
  value, 
  onChange, 
  disabled, 
  icon, 
  error, 
  required = false 
}) => {
  // Sanitize input value based on type
  const sanitizeInput = (value) => {
    switch (type) {
      case 'email':
        return value.trim().toLowerCase();
      case 'tel':
        return value.replace(/[^\d+\-() ]/g, '');
      default:
        if (name === 'occupation') {
          return value.replace(/\s+/g, ' ');
        }
        return value.trim();
  }
  };

  const handleChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    e.target.value = sanitizedValue;
    onChange(e);
  };

  return (
    <div>
      <label htmlFor={name} className="block text-sm font-medium text-gray-700 mb-1">
        {label}
        {required && <span className="text-red-500 ml-1">*</span>}
      </label>
      <div className="relative rounded-lg shadow-sm">
        {icon && (
          <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
            {icon}
          </div>
        )}
        <input
          type={type}
          id={name}
          name={name}
          value={value}
          onChange={handleChange}
          disabled={disabled}
          required={required}
          aria-invalid={error ? 'true' : 'false'}
          aria-describedby={error ? `${name}-error` : undefined}
          className={`w-full py-3 ${icon ? 'pl-10' : 'pl-4'} pr-4 border ${
            disabled 
              ? 'bg-gray-50 text-gray-500 border-gray-200' 
              : error
                ? 'bg-white text-gray-900 border-red-300 focus:ring-red-500'
                : 'bg-white text-gray-900 border-gray-300 focus:ring-blue-500'
          } rounded-lg focus:outline-none focus:ring-2 focus:border-transparent transition-colors duration-200`}
        />
      </div>
      {error && (
        <p id={`${name}-error`} className="mt-1 text-sm text-red-600" role="alert">
          {error}
        </p>
      )}
    </div>
  );
};

export default AccountDetailsPage;