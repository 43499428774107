import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTasks, 
  faSpinner, 
  faSync,
} from '@fortawesome/free-solid-svg-icons';  

const ActionableTasks = ({ tasks, onRefreshTask, updatingTaskIndex }) => {
    if (!tasks || tasks.length === 0) {
      return (
        <div className="bg-white rounded-lg shadow-md p-6 mb-8">
          <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
            <FontAwesomeIcon icon={faTasks} className="text-blue-500 mr-3" />
            Actionable Tasks
          </h2>
          <p className="text-gray-500 text-center">No actionable tasks available.</p>
        </div>
      );
    }
  
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <h2 className="text-2xl font-bold mb-6 text-gray-800 flex items-center">
          <FontAwesomeIcon icon={faTasks} className="text-blue-500 mr-3" />
          Actionable Tasks
        </h2>
        <ul className="space-y-4">
          {tasks.map((task, index) => (
            <li
              key={index}
              className="flex items-center justify-between p-4 bg-gray-50 rounded-lg border border-gray-100 hover:bg-gray-100 transition-colors duration-200"
            >
              <span className="text-gray-700 flex-1">{task}</span>
              <button
                type="button"
                onClick={() => onRefreshTask(index)}
                disabled={updatingTaskIndex !== null}
                className="ml-2 text-gray-500 hover:text-blue-500 focus:outline-none disabled:opacity-50 disabled:cursor-not-allowed"
                title="Refresh this task"
              >
                <FontAwesomeIcon
                  icon={updatingTaskIndex === index ? faSpinner : faSync}
                  className={updatingTaskIndex === index ? 'animate-spin' : 'hover:rotate-180 transition-transform duration-300'}
                />
              </button>
            </li>
          ))}
        </ul>
      </div>
    );
  };

export default ActionableTasks;