import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faTasks, 
  faChevronUp, 
  faChevronDown, 
} from '@fortawesome/free-solid-svg-icons';

const GamePlan = ({ goal, completedSubtasks, onSubtaskToggle, onUpdateTasks }) => {
    const [expandedPhases, setExpandedPhases] = useState([]);
  
    const togglePhase = (phaseIndex) => {
      setExpandedPhases(prevExpanded => {
        if (prevExpanded.includes(phaseIndex)) {
          return prevExpanded.filter(index => index !== phaseIndex);
        } else {
          return [...prevExpanded, phaseIndex];
        }
      });
    };

    const getSubtaskStatus = (phaseIndex, taskIndex, subtaskIndex) => {
      return completedSubtasks.some(
        task => 
          task.phaseIndex === phaseIndex && 
          task.taskIndex === taskIndex && 
          task.subtaskIndex === subtaskIndex
      );
    };
  
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-6">
        <h2 className="text-xl font-bold text-gray-800 mb-6 flex items-center">
          <FontAwesomeIcon icon={faTasks} className="text-blue-500 mr-3" />
          Game Plan
        </h2>
  
        {goal.gamePlan ? (
          <div className="space-y-6">
            {goal.gamePlan.phases.map((phase, phaseIndex) => (
              <div 
                key={phaseIndex}
                className="border border-gray-100 rounded-lg overflow-hidden hover:shadow-md transition-all duration-300"
              >
                <button
                  onClick={() => togglePhase(phaseIndex)}
                  className="w-full flex items-center justify-between p-4 bg-gray-50 hover:bg-gray-100 transition-colors duration-200"
                >
                  <div className="flex items-center">
                    <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
                      <span className="font-semibold text-blue-500">{phaseIndex + 1}</span>
                    </div>
                    <div className="text-left">
                      <h3 className="font-semibold text-gray-800">{phase.name}</h3>
                      <span className="text-sm text-gray-500">{phase.duration}</span>
                    </div>
                  </div>
                  <FontAwesomeIcon
                    icon={expandedPhases.includes(phaseIndex) ? faChevronUp : faChevronDown}
                    className="text-gray-400 transition-transform duration-200"
                  />
                </button>
  
                {expandedPhases.includes(phaseIndex) && (
                  <div className="p-4 bg-white">
                    {phase.tasks.map((task, taskIndex) => (
                      <div key={taskIndex} className="mb-4 last:mb-0">
                        <div className="flex items-start mb-2">
                          <div className="w-6 h-6 rounded-full bg-blue-50 flex items-center justify-center mr-2 mt-1">
                            <span className="text-sm text-blue-500">{taskIndex + 1}</span>
                          </div>
                          <p className="text-gray-700 flex-1">{task.description}</p>
                        </div>
                        {task.subtasks && task.subtasks.length > 0 && (
                          <div className="ml-8 space-y-2">
                            {task.subtasks.map((subtask, subtaskIndex) => (
                              <label
                                key={subtaskIndex}
                                className="flex items-center space-x-3 p-2 rounded-lg hover:bg-gray-50 transition-colors duration-200"
                              >
                                <input
                                  type="checkbox"
                                  checked={getSubtaskStatus(phaseIndex, taskIndex, subtaskIndex)}
                                  onChange={() => onSubtaskToggle(phaseIndex, taskIndex, subtaskIndex)}
                                  className="w-4 h-4 text-blue-500 rounded border-gray-300 focus:ring-blue-500"
                                />
                                <span className={`text-sm ${
                                  getSubtaskStatus(phaseIndex, taskIndex, subtaskIndex)
                                    ? 'text-gray-400 line-through'
                                    : 'text-gray-600'
                                }`}>
                                  {subtask}
                                </span>
                              </label>
                            ))}
                          </div>
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            ))}
  
            {/* Daily Breakdown Section */}
            <div className="mt-8 bg-gray-50 rounded-lg p-6">
              <h3 className="text-lg font-semibold text-gray-800 mb-4">Daily Breakdown</h3>
              <div className="grid grid-cols-1 gap-4">
                {goal.gamePlan.dailyBreakdown.schedule.map((item, index) => (
                  <div 
                    key={index}
                    className="bg-white rounded-lg p-4 shadow-sm hover:shadow-md transition-all duration-300"
                  >
                    <div className="flex items-center mb-2">
                      <span className="font-semibold text-blue-500">{item.day}</span>
                      <span className="text-gray-400 mx-2">•</span>
                      <span className="text-sm text-gray-500">{item.duration}</span>
                    </div>
                    <p className="text-gray-600">{item.activity}</p>
                  </div>
                ))}
              </div>
            </div>
  
            {/* Timeline Summary */}
            <div className="mt-6 bg-blue-50 rounded-lg p-4">
              <p className="text-gray-700 italic">
                {goal.gamePlan.summaryTimeline}
              </p>
            </div>
          </div>
        ) : (
          <p className="text-gray-500 italic">No game plan available.</p>
        )}
      </div>
    );
  };

export default GamePlan;