import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faListAlt,
} from '@fortawesome/free-solid-svg-icons';


const SmartDetails = ({ goal }) => (
  <div className="bg-white rounded-lg shadow-md p-6 mb-6">
    <div className="flex items-center mb-4">
      <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
        <FontAwesomeIcon icon={faListAlt} className="text-blue-500" />
      </div>
      <h2 className="text-xl font-bold text-gray-800">S.M.A.R.T. Goal Details</h2>
    </div>

    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
      {[
        { label: 'Specific', key: 'specific' },
        { label: 'Measurable', key: 'measurable' },
        { label: 'Achievable', key: 'achievable' },
        { label: 'Relevant', key: 'relevant' }
      ].map(({ label, key }) => (
        <div key={key} className="bg-gray-50 rounded-lg p-4">
          <h3 className="text-sm font-semibold text-blue-600 uppercase tracking-wider mb-2">
            {label}
          </h3>
          <p className="text-gray-700">
            {goal[key] || 'Not specified'}
          </p>
        </div>
      ))}
    </div>
  </div>
);

  export default SmartDetails;