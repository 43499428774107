// stripeCheckout.js
import { loadStripe } from '@stripe/stripe-js';
import api from '../api';

const STRIPE_PUBLIC_KEY = process.env.REACT_APP_STRIPE_PUBLIC_KEY;
const PRICE_ID = process.env.REACT_APP_STRIPE_PRICE_ID || 'price_1QL9v5AlAfmxCgJQGPUK3jJ6';

if (!STRIPE_PUBLIC_KEY) {
}

const stripePromise = loadStripe(STRIPE_PUBLIC_KEY);

export const useStripeCheckout = (userId, userEmail) => {
  const createCheckoutSession = async (checkoutData) => {
    
    const response = await api.post('/payments/checkout', checkoutData);
    
    // Check for both possible response formats
    if (!response.data?.sessionId && !response.data?.id) {
      throw new Error('Invalid checkout session response');
    }
    
    // Use sessionId if available, otherwise fall back to id
    const sessionId = response.data.sessionId || response.data.id;
    
    return {
      sessionId,
      url: response.data.url
    };
  };

  const handleUpgrade = async () => {
    try {
      if (!userId || !userEmail) {
        throw new Error(`Missing required data: userId=${userId}, email=${userEmail}`);
      }

      console.log('User data for checkout:', {
        userId,
        userEmail,
        user: { id: userId, email: userEmail }
      });
      
      const checkoutData = {
        priceId: PRICE_ID,
        clientReferenceId: userId,
        customerEmail: userEmail,
        successUrl: `${window.location.origin}/payments/success`,
        cancelUrl: `${window.location.origin}/account`
      };


      const { sessionId, url } = await createCheckoutSession(checkoutData);

      // If we have a direct URL, use it
      if (url) {
        window.location.href = url;
        return;
      }

      // Otherwise use Stripe's redirect
      const stripe = await stripePromise;
      if (!stripe) {
        throw new Error('Failed to load Stripe');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId
      });

      if (error) {
        console.error('Stripe redirect error:', {
          message: error.message,
          type: error.type,
          code: error.code
        });
        throw error;
      }

    } catch (error) {
      console.error('Checkout error:', {
        message: error.message,
        response: error.response?.data,
        stack: error.stack
      });
      
      const errorMessage = error.response?.data?.error || 
                          error.message || 
                          'Failed to process upgrade. Please try again.';
      
      throw new Error(errorMessage);
    }
  };

  const handleCancelSubscription = async () => {
    try {
      if (!userId) {
        throw new Error('Missing user ID for subscription cancellation');
      }

      
      const response = await api.post('/payments/cancel-subscription', { 
        userId,
        cancelReason: 'user_requested'
      });

      return response.data;
      
    } catch (error) {
      console.error('Subscription cancellation error:', {
        message: error.message,
        response: error.response?.data,
        stack: error.stack
      });
      
      const errorMessage = error.response?.data?.error || 
                          error.message || 
                          'Failed to cancel subscription. Please try again.';
                          
      throw new Error(errorMessage);
    }
  };

  return { handleUpgrade, handleCancelSubscription };
};