import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from '../utils/analytics';

const Login = () => {
  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [loginAttempts, setLoginAttempts] = useState(0);
  
  const navigate = useNavigate();
  const { login } = useAuth();

  // Email validation
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Input sanitization
  const sanitizeInput = (value, type) => {
    switch (type) {
      case 'email':
        return value.trim().toLowerCase();
      case 'password':
        // Remove any non-printable characters and limit length
        return value.replace(/[^\x20-\x7E]/g, '').slice(0, 128);
      default:
        return value;
    }
  };

  const validateForm = () => {
    const errors = {};
    const sanitizedEmail = sanitizeInput(formData.email, 'email');

    if (!sanitizedEmail) {
      errors.email = 'Email is required';
    } else if (!isValidEmail(sanitizedEmail)) {
      errors.email = 'Please enter a valid email address';
    }

    if (!formData.password) {
      errors.password = 'Password is required';
    }

    return errors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const sanitizedValue = sanitizeInput(value, id);

    setFormData(prev => ({
      ...prev,
      [id]: sanitizedValue
    }));

    // Clear error for this field when user starts typing
    if (errors[id]) {
      setErrors(prev => ({
        ...prev,
        [id]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});

    // Check if too many login attempts
    if (loginAttempts >= 5) {
      setErrors({
        general: 'Too many login attempts. Please try again later.'
      });
      return;
    }

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }

    setIsLoading(true);

    try {
      // Sanitize data before sending
      const sanitizedData = {
        email: sanitizeInput(formData.email, 'email'),
        password: sanitizeInput(formData.password, 'password')
      };

      const response = await login(sanitizedData);
      
      if (response?.token) {
        // Securely store the token
        try {
          trackEvent('login', { success: true });
          localStorage.setItem('token', response.token);
          navigate('/dashboard');
        } catch (storageError) {
          trackEvent('login', { success: false });
          setErrors({
            general: 'Failed to store authentication data. Please ensure cookies are enabled.'
          });
        }
      } else {
        throw new Error('Invalid login response');
      }
    } catch (error) {
      setLoginAttempts(prev => prev + 1);
      
      // Handle different types of errors
      if (error.response?.status === 401) {
        setErrors({
          general: 'Invalid email or password'
        });
      } else if (error.response?.status === 429) {
        setErrors({
          general: 'Too many login attempts. Please try again later.'
        });
      } else {
        setErrors({
          general: 'An error occurred. Please try again.'
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errors.general && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg text-sm">
          {errors.general}
        </div>
      )}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email
        </label>
        <div className="mt-1">
          <input
            type="email"
            id="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            className={`appearance-none block w-full px-4 py-3 border ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            } rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
            placeholder="Enter your email"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email}</p>
          )}
        </div>
      </div>

      <div>
        <label htmlFor="password" className="block text-sm font-medium text-gray-700">
          Password
        </label>
        <div className="mt-1 relative">
          <input
            type={showPassword ? "text" : "password"}
            id="password"
            autoComplete="current-password"
            value={formData.password}
            onChange={handleChange}
            className={`appearance-none block w-full px-4 py-3 border ${
              errors.password ? 'border-red-500' : 'border-gray-300'
            } rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
            placeholder="Enter your password"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
            onClick={() => setShowPassword(!showPassword)}
          >
            <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
          </button>
          {errors.password && (
            <p className="mt-1 text-sm text-red-500">{errors.password}</p>
          )}
        </div>
      </div>

      <div>
        <button
          type="submit"
          disabled={isLoading || loginAttempts >= 5}
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-lg font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
        >
          {isLoading ? (
            <span className="flex items-center">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
              Signing in...
            </span>
          ) : (
            'Sign in'
          )}
        </button>
      </div>

      <div className="flex items-center justify-center">
        {/* Uncomment when forgot password is implemented
        <Link
          to="/forgot-password"
          className="font-medium text-blue-500 hover:text-blue-600 transition-colors duration-300"
        >
          Forgot your password?
        </Link>
        */}
      </div>
    </form>
  );
};

export default Login;