import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

const api = axios.create({
  baseURL: `${API_URL}/api`,
  headers: {
    'Content-Type': 'application/json',
  },
});

// Add a request interceptor to include the token in all requests
api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor for better error handling
api.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export const registerUser = async (userData) => {
  try {
    const response = await api.post('/users/register', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const loginUser = async (userData) => {
  try {
    const response = await api.post('/users/login', userData);
    if (response.data.token) {
      localStorage.setItem('token', response.data.token);
    }
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const saveOnboardingData = async (onboardingData) => {
  try {
    const response = await api.post('/users/onboarding', onboardingData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getGoalById = async (goalId) => {
  try {
    const response = await api.get(`/goals/${goalId}`);
    const goal = response.data;
    
    // Handle potential legacy goal structure
    if (typeof goal.gamePlan === 'string') {
      goal.gamePlan = {
        phases: [],
        dailyBreakdown: { description: '', schedule: [] },
        summaryTimeline: goal.gamePlan
      };
    }
    if (!goal.resources) goal.resources = [];
    if (!goal.actionableTasks) goal.actionableTasks = [];
    
    // Ensure notes is always an array
    if (!Array.isArray(goal.notes)) {
      goal.notes = goal.notes ? [{ id: Date.now(), content: goal.notes }] : [];
    }
    
    return goal;
  } catch (error) {
    throw error;
  }
};

export const saveGoal = async (goalData) => {
  try {
    const response = await api.post('/goals', goalData);
    return response.data;
  } catch (error) {
    if (error.response) {
    }
    if (error.response && error.response.data) {
      throw error.response.data;
    } else {
      throw error;
    }
  }
};

export const fetchGoals = async () => {
  try {
    const response = await api.get('/goals');
    // Handle potential legacy goal structures
    response.data = response.data.map(goal => {
      if (typeof goal.gamePlan === 'string') {
        goal.gamePlan = {
          phases: [],
          dailyBreakdown: { description: '', schedule: [] },
          summaryTimeline: goal.gamePlan
        };
      }
      if (!goal.resources) goal.resources = [];
      if (!goal.actionableTasks) goal.actionableTasks = [];
      // Ensure notes is always an array
      if (!Array.isArray(goal.notes)) {
        goal.notes = goal.notes ? [{ id: Date.now(), content: goal.notes }] : [];
      }
      return goal;
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const deleteGoal = async (goalId) => {
  try {
    const response = await api.delete(`/goals/${goalId}`);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateGoalNotes = async (goalId, notes) => {
  try {
    const response = await api.put(`/goals/${goalId}/notes`, { notes });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateUser = async (userData) => {
  try {
    const response = await api.put('/users/update', userData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const fetchUserData = async () => {
  try {
    const response = await api.get('/users/me');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMentorInteractions = async () => {
  try {
    const response = await api.get('/users/mentor-interactions');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateMentorInteractions = async () => {
  try {
    const response = await api.post('/users/mentor-interactions');
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const getMentorFeedback = async (goalId, userInput) => {
  try {
    const response = await api.post(`/goals/${goalId}/mentor-feedback`, { 
      userInput,
      userPreferences: {} 
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateCompletedSubtasks = async (goalId, subtaskData) => {
  try {
    const response = await api.put(`/goals/${goalId}/completed-subtasks`, subtaskData);
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const updateActionableTasks = async (goalId, taskIndex) => {
  try {
    const response = await api.put(`/goals/${goalId}/actionable-tasks`, { taskIndex });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const processPayment = async () => {
  const response = await fetch(`${API_URL}/payment`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    credentials: 'include',
  });
  return response.json();
};

export default api;