import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faLightbulb,
  faExclamationTriangle,
  faBullseye,
  faChartLine,
  faCheckCircle,
  faCrosshairs,
  faClock,
  faHourglassHalf,
  faClipboardCheck,
  faCheckDouble,
  faRocket
} from '@fortawesome/free-solid-svg-icons';

// Import components
import GoalSpecificStep from '../components/GoalSpecificStep';
import GoalMeasurableStep from '../components/GoalMeasurableStep';
import GoalAchievableStep from '../components/GoalAchievableStep';
import GoalRelevantStep from '../components/GoalRelevantStep';
import GoalTimeboundStep from '../components/GoalTimeboundStep';
import GoalHoursStep from '../components/GoalHoursStep';
import GoalReviewStep from '../components/GoalReviewStep';
import LoadingSpinner from '../components/LoadingSpinner';
import { saveGoal } from '../api';

const LoadingOverlay = () => (
  <div className="fixed inset-0 bg-white/80 backdrop-blur-sm z-50 flex items-center justify-center">
    <div className="bg-white p-8 rounded-xl shadow-lg text-center">
      <div className="mb-4">
        <LoadingSpinner />
      </div>
      <p className="text-gray-600 animate-pulse">Saving your goal...</p>
    </div>
  </div>
);

const SmartGoalWizard = () => {
  // State management
  const [currentStep, setCurrentStep] = useState(0);
  const [goalData, setGoalData] = useState({
    specific: '',
    measurable: '',
    achievable: '',
    relevant: '',
    timebound: '',
    hours: '',
    resources: [],
  });
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [feedback, setFeedback] = useState('');
  const [suggestedModifications, setSuggestedModifications] = useState('');
  const [aiSuggestions, setAiSuggestions] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();

  // Scroll lock effect
  useEffect(() => {
    if (isSubmitting) {
      // Scroll to top smoothly
      window.scrollTo({ top: 0, behavior: 'smooth' });
      // Prevent scrolling
      document.body.style.overflow = 'hidden';
    } else {
      // Re-enable scrolling
      document.body.style.overflow = 'unset';
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = 'unset';
    };
  }, [isSubmitting]);

  // Step definitions
  const steps = [
    {
      name: 'Specific',
      description: 'Define the goal',
      icon: faBullseye,
      color: 'text-blue-500'
    },
    {
      name: 'Measurable',
      description: 'Set metrics',
      icon: faChartLine,
      color: 'text-green-500'
    },
    {
      name: 'Achievable',
      description: 'Assess resources',
      icon: faCheckCircle,
      color: 'text-yellow-500'
    },
    {
      name: 'Relevant',
      description: 'Align with objectives',
      icon: faCrosshairs,
      color: 'text-purple-500'
    },
    {
      name: 'Time-bound',
      description: 'Set deadline',
      icon: faClock,
      color: 'text-orange-500'
    },
    {
      name: 'Hours',
      description: 'Plan time',
      icon: faHourglassHalf,
      color: 'text-indigo-500'
    },
    {
      name: 'Review',
      description: 'Finalize',
      icon: faClipboardCheck,
      color: 'text-red-500'
    }
  ];

  // Handlers
  const handleNext = (stepData) => {
    setGoalData(prev => ({ ...prev, ...stepData }));
    setCurrentStep(prev => prev + 1);
  };

  const handlePrevious = () => {
    setCurrentStep(prev => prev - 1);
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setError('');
    setSuccess('');
    setFeedback('');
    setSuggestedModifications('');
    setAiSuggestions(null);
    setIsLoading(true);

    try {
      const response = await saveGoal(goalData);
      
      if (response.goal) {
        setSuccess(response.message);
        setGoalData(response.goal);
        if (response.aiSuggestions) {
          setAiSuggestions(response.aiSuggestions);
        }
        setTimeout(() => {
          navigate(`/goals/${response.goal._id}`);
        }, 2000);
      } else if (response.feedback) {
        setFeedback(response.feedback);
        if (response.suggestedModifications) {
          setSuggestedModifications(response.suggestedModifications);
        }
        if (response.aiSuggestions) {
          setAiSuggestions(response.aiSuggestions);
        }
        if (response.resources) {
          setGoalData(prev => ({ ...prev, resources: response.resources }));
        }
      }
    } catch (error) {
      if (error.feedback) {
        setFeedback(error.feedback);
        if (error.suggestedModifications) {
          setSuggestedModifications(error.suggestedModifications);
        }
        if (error.aiSuggestions) {
          setAiSuggestions(error.aiSuggestions);
        }
        if (error.resources) {
          setGoalData(prev => ({ ...prev, resources: error.resources }));
        }
      } else {
        setError(error.message || 'An unexpected error occurred. Please try again.');
      }
    } finally {
      setIsLoading(false);
      setIsSubmitting(false);
    }
  };

  const integrateAiFeedback = () => {
    if (aiSuggestions) {
      setGoalData(prev => ({
        ...prev,
        ...aiSuggestions
      }));
      setAiSuggestions(null);
      setFeedback('');
      setSuggestedModifications('');
      setCurrentStep(0);
    }
  };

  const renderStep = () => {
    const commonProps = {
      goalData,
      setGoalData,
      onNext: handleNext,
      onPrevious: handlePrevious
    };

    switch (currentStep) {
      case 0:
        return <GoalSpecificStep {...commonProps} />;
      case 1:
        return <GoalMeasurableStep {...commonProps} />;
      case 2:
        return <GoalAchievableStep {...commonProps} />;
      case 3:
        return <GoalRelevantStep {...commonProps} />;
      case 4:
        return <GoalTimeboundStep {...commonProps} />;
      case 5:
        return <GoalHoursStep {...commonProps} />;
      case 6:
        return <GoalReviewStep {...commonProps} onSubmit={handleSubmit} isSubmitting={isSubmitting} />;
      default:
        return null;
    }
  };

  // Progress calculation
  const progress = ((currentStep + 1) / steps.length) * 100;

  return (
    <>
      {isSubmitting && <LoadingOverlay />}
      <div className="min-h-screen bg-gradient-to-b from-gray-50 to-blue-50 pt-2 md:pt-16 pb-20 px-4 sm:px-6 lg:px-8">
        <div className="max-w-4xl mx-auto">
          {/* Header Section */}
          <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-t-2xl shadow-lg p-8 text-white relative overflow-hidden">
            {/* Background Decoration */}
            <div className="absolute inset-0 opacity-10">
              <div className="absolute -right-10 -top-10 w-40 h-40 bg-white rounded-full" />
              <div className="absolute -left-10 -bottom-10 w-40 h-40 bg-white rounded-full" />
            </div>
            
            <div className="relative">
              <h1 className="text-3xl font-bold mb-2">Create Your S.M.A.R.T. Goal</h1>
              <p className="text-blue-100">Follow the steps below to create a well-defined and achievable goal.</p>
            </div>
          </div>

          <div className="bg-white rounded-b-2xl shadow-lg overflow-hidden">
            {/* Progress Steps */}
            <div className="px-8 py-4 bg-gray-50 border-b border-gray-200">
              <div className="flex justify-between mb-4">
                {steps.map((step, index) => (
                  <div 
                    key={index}
                    className={`flex flex-col items-center ${
                      index < currentStep 
                        ? 'text-green-500' 
                        : index === currentStep 
                          ? step.color
                          : 'text-gray-300'
                    }`}
                  >
                    <div 
                      className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 ${
                        index < currentStep 
                          ? 'bg-green-100' 
                          : index === currentStep 
                            ? 'bg-blue-100 animate-pulse'
                            : 'bg-gray-100'
                      }`}
                    >
                      <FontAwesomeIcon 
                        icon={index < currentStep ? faCheckDouble : step.icon} 
                        className={index === currentStep ? 'animate-bounce' : ''}
                      />
                    </div>
                    <span className="hidden md:block text-xs mt-1 font-medium">
                      {step.name}
                    </span>
                    <span className="hidden md:block text-xs text-gray-500">
                      {step.description}
                    </span>
                  </div>
                ))}
              </div>
              <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
                <div
                  className="h-full bg-gradient-to-r from-blue-500 to-blue-600 rounded-full transition-all duration-500 ease-out"
                  style={{ width: `${progress}%` }}
                />
              </div>
            </div>

            {/* Main Content Area */}
            <div className="p-6 sm:p-8">
              {/* Feedback Panel */}
              {!success && (feedback || suggestedModifications || aiSuggestions || goalData.resources.length > 0) && (
                <div className="mb-8 bg-white rounded-xl border border-blue-100 shadow-sm overflow-hidden transform transition-all duration-300 hover:shadow-md">
                  <div className="px-6 py-4 bg-gradient-to-r from-blue-50 to-blue-100 border-b border-blue-100">
                    <h2 className="text-lg font-semibold text-blue-800 flex items-center">
                      <FontAwesomeIcon icon={faLightbulb} className="mr-3" />
                      AI Mentor Feedback
                    </h2>
                  </div>
                  <div className="p-6">
                    {feedback && (
                      <div className="prose max-w-none text-gray-600 mb-4">
                        {feedback}
                      </div>
                    )}
                    {suggestedModifications && (
                      <div className="mt-4 p-4 bg-gradient-to-r from-blue-50 to-blue-100 rounded-lg">
                        <h4 className="font-medium text-blue-800 mb-2">
                          Suggested Improvements:
                        </h4>
                        <p className="text-blue-600">{suggestedModifications}</p>
                      </div>
                    )}
                    {aiSuggestions && (
                      <div className="mt-6">
                        <button
                          onClick={integrateAiFeedback}
                          className="w-full flex items-center justify-center px-4 py-3 bg-gradient-to-r from-green-500 to-green-600 text-white rounded-lg hover:from-green-600 hover:to-green-700 transition-all duration-300 transform hover:scale-105"
                        >
                          <FontAwesomeIcon 
                            icon={faRocket} 
                            className="mr-2" 
                          />
                          Apply AI Suggestions
                        </button>
                      </div>
                    )}
                  </div>
                </div>
              )}

              {/* Status Messages */}
              {error && (
                <div className="mb-6 p-4 bg-red-50 border-l-4 border-red-500 rounded-r-lg text-red-600 animate-fadeIn">
                  <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                  {error}
                </div>
              )}

              {success && (
                <div className="mb-6 p-4 bg-green-50 border-l-4 border-green-500 rounded-r-lg text-green-600 animate-fadeIn">
                  <FontAwesomeIcon icon={faCheckCircle} className="mr-2" />
                  {success}
                </div>
              )}

              {/* Step Content */}
              <div className="min-h-[400px] transition-all duration-500">
                {isLoading ? (
                  <div className="flex items-center justify-center h-[400px]">
                    <LoadingSpinner />
                  </div>
                ) : (
                  <div className="animate-fadeIn">
                    {renderStep()}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SmartGoalWizard;