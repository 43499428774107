import React, { useState, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { registerUser } from '../api';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faSpinner, faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';
import { trackEvent } from '../utils/analytics'; 

const PasswordRequirements = ({ password, className = "" }) => {
  const requirements = {
    length: password.length >= 8,
    lowercase: /[a-z]/.test(password),
    uppercase: /[A-Z]/.test(password),
    number: /\d/.test(password),
    special: /[!@#$%^&*]/.test(password)
  };

  const RequirementRow = ({ met, text }) => (
    <div className="flex items-center space-x-2">
      <FontAwesomeIcon 
        icon={met ? faCheck : faXmark} 
        className={met ? "text-green-500 w-4 h-4" : "text-gray-300 w-4 h-4"} 
      />
      <span className={`text-sm ${met ? 'text-green-500' : 'text-gray-500'}`}>
        {text}
      </span>
    </div>
  );

  return (
    <div className={`bg-white rounded-lg border border-gray-200 p-4 space-y-2 ${className}`}>
      <div className="text-sm font-medium text-gray-700 mb-2">
        Password requirements:
      </div>
      <RequirementRow met={requirements.length} text="At least 8 characters" />
      <RequirementRow met={requirements.lowercase} text="One lowercase letter" />
      <RequirementRow met={requirements.uppercase} text="One uppercase letter" />
      <RequirementRow met={requirements.number} text="One number" />
      <RequirementRow met={requirements.special} text="One special character (!@#$%^&*)" />
    </div>
  );
};

const Signup = ({ isPremiumFlow = false }) => {
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  
  const navigate = useNavigate();
  const { login } = useAuth();

  // Email validation with comprehensive regex
  const isValidEmail = (email) => {
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailRegex.test(email);
  };

  // Password requirements checker
  const checkPasswordRequirements = useCallback((password) => {
    const requirements = {
      length: password.length >= 8,
      lowercase: /[a-z]/.test(password),
      uppercase: /[A-Z]/.test(password),
      number: /\d/.test(password),
      special: /[!@#$%^&*]/.test(password)
    };

    return requirements;
  }, []);

  // Sanitize input
  const sanitizeInput = (value, type) => {
    switch (type) {
      case 'email':
        return value.trim().toLowerCase();
      case 'password':
        // Remove any non-printable characters and limit length
        return value.replace(/[^\x20-\x7E]/g, '').slice(0, 128);
      default:
        return value;
    }
  };

  const validateForm = () => {
    const validationErrors = {};

    // Email validation
    const sanitizedEmail = sanitizeInput(formData.email, 'email');
    if (!sanitizedEmail) {
      validationErrors.email = 'Email is required';
    } else if (!isValidEmail(sanitizedEmail)) {
      validationErrors.email = 'Please enter a valid email address';
    }

    // Password validation with comprehensive checks
    const sanitizedPassword = sanitizeInput(formData.password, 'password');
    if (!sanitizedPassword) {
      validationErrors.password = 'Password is required';
    } else {
      const requirements = checkPasswordRequirements(sanitizedPassword);
      
      if (!requirements.length) {
        validationErrors.password = 'Password must be at least 8 characters long';
      } else if (!requirements.lowercase) {
        validationErrors.password = 'Password must contain at least one lowercase letter';
      } else if (!requirements.uppercase) {
        validationErrors.password = 'Password must contain at least one uppercase letter';
      } else if (!requirements.number) {
        validationErrors.password = 'Password must contain at least one number';
      } else if (!requirements.special) {
        validationErrors.password = 'Password must contain at least one special character (!@#$%^&*)';
      }
    }

    // Confirm password validation
    if (!formData.confirmPassword) {
      validationErrors.confirmPassword = 'Please confirm your password';
    } else if (formData.password !== formData.confirmPassword) {
      validationErrors.confirmPassword = 'Passwords do not match';
    }

    return validationErrors;
  };

  const handleChange = (e) => {
    const { id, value } = e.target;
    const sanitizedValue = sanitizeInput(value, id);

    setFormData(prev => ({
      ...prev,
      [id]: sanitizedValue
    }));

    // Clear error for this field when user starts typing
    if (errors[id]) {
      setErrors(prev => ({
        ...prev,
        [id]: ''
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrors({});
    
    const validationErrors = validateForm();
    
    if (Object.keys(validationErrors).length === 0) {
      try {
        const sanitizedData = {
          email: sanitizeInput(formData.email, 'email'),
          password: sanitizeInput(formData.password, 'password')
        };
  
        const registerResponse = await registerUser(sanitizedData);
        
        if (registerResponse.success) {
          const loginData = await login(sanitizedData);
          trackEvent('sign_up', {
            success: true,
            is_premium_flow: isPremiumFlow
          });

          if (loginData.user) {
            // Make sure premium parameter is being passed
            const redirectUrl = isPremiumFlow ? '/onboarding?premium=true' : '/onboarding';
            navigate(redirectUrl);
          } else {
            setErrors({ general: 'Account created but login failed. Please try logging in.' });
            setTimeout(() => navigate('/login'), 2000);
          }
        } else {
          trackEvent('sign_up', {
            success: false,
            reason: 'registration_failed',
            is_premium_flow: isPremiumFlow
          });
          setErrors({
            general: registerResponse.message || 'Registration failed. Please try again.'
          });
        }
      } catch (error) {
        trackEvent('sign_up', {
          success: false,
          reason: error.response?.data?.message || 'unknown_error',
          is_premium_flow: isPremiumFlow
        });
        const errorMessage = error.response?.data?.message || 'An error occurred. Please try again.';
        setErrors({
          general: errorMessage.includes('duplicate') ? 'This email is already registered.' : errorMessage
        });
      }
    } else {
      setErrors(validationErrors);
    }
    
    setIsLoading(false);
  };

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword(!showPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-6">
      {errors.general && (
        <div className="bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg text-sm">
          {errors.general}
        </div>
      )}

      <div>
        <label htmlFor="email" className="block text-sm font-medium text-gray-700">
          Email address
        </label>
        <div className="mt-1">
          <input
            type="email"
            id="email"
            autoComplete="email"
            value={formData.email}
            onChange={handleChange}
            className={`appearance-none block w-full px-4 py-3 border ${
              errors.email ? 'border-red-500' : 'border-gray-300'
            } rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
            placeholder="Enter your email"
          />
          {errors.email && (
            <p className="mt-1 text-sm text-red-500">{errors.email}</p>
          )}
        </div>
      </div>

      <div>
  <label htmlFor="password" className="block text-sm font-medium text-gray-700">
    Password
  </label>
  <div className="mt-1">
    <div className="relative">
      <input
        type={showPassword ? "text" : "password"}
        id="password"
        autoComplete="new-password"
        value={formData.password}
        onChange={handleChange}
        className={`appearance-none block w-full px-4 py-3 border ${
          errors.password ? 'border-red-500' : 'border-gray-300'
        } rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
        placeholder="Create a password"
      />
      <button
        type="button"
        className="absolute top-1/2 -translate-y-1/2 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
        onClick={() => togglePasswordVisibility('password')}
      >
        <FontAwesomeIcon icon={showPassword ? faEyeSlash : faEye} />
      </button>
    </div>
    {errors.password && (
      <p className="mt-1 text-sm text-red-500">{errors.password}</p>
    )}
    {formData.password.length > 0 && (
      <PasswordRequirements 
        password={formData.password}
        className="mt-2"
      />
    )}
  </div>
</div>

      <div>
        <label htmlFor="confirmPassword" className="block text-sm font-medium text-gray-700">
          Confirm Password
        </label>
        <div className="mt-1 relative">
          <input
            type={showConfirmPassword ? "text" : "password"}
            id="confirmPassword"
            autoComplete="new-password"
            value={formData.confirmPassword}
            onChange={handleChange}
            className={`appearance-none block w-full px-4 py-3 border ${
              errors.confirmPassword ? 'border-red-500' : 'border-gray-300'
            } rounded-lg shadow-sm placeholder-gray-400 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
            placeholder="Confirm your password"
          />
          <button
            type="button"
            className="absolute inset-y-0 right-0 pr-3 flex items-center text-gray-400 hover:text-gray-600"
            onClick={() => togglePasswordVisibility('confirm')}
          >
            <FontAwesomeIcon icon={showConfirmPassword ? faEyeSlash : faEye} />
          </button>
          {errors.confirmPassword && (
            <p className="mt-1 text-sm text-red-500">{errors.confirmPassword}</p>
          )}
        </div>
      </div>

      <div>
        <button
          type="submit"
          disabled={isLoading}
          className="w-full flex justify-center py-3 px-4 border border-transparent rounded-lg shadow-sm text-lg font-medium text-white bg-blue-500 hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 transform hover:scale-105 disabled:opacity-50 disabled:cursor-not-allowed disabled:hover:scale-100"
        >
          {isLoading ? (
            <span className="flex items-center">
              <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
              Creating account...
            </span>
          ) : (
            'Create account'
          )}
        </button>
      </div>

      <div className="text-sm text-gray-600">
        By signing up, you agree to our{' '}
        <a href="/terms" className="font-medium text-blue-500 hover:text-blue-600 transition-colors duration-300">
          Terms of Service
        </a>{' '}
        and{' '}
        <a href="/privacy" className="font-medium text-blue-500 hover:text-blue-600 transition-colors duration-300">
          Privacy Policy
        </a>
      </div>
    </form>
  );
};

export default Signup;