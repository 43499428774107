import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSpinner, 
  faBell, 
  faClock, 
  faEnvelope, 
  faChevronDown, 
  faChevronUp 
} from '@fortawesome/free-solid-svg-icons';
import UpgradeModal from './UpgradeModal';

const API_BASE_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

const GoalCheckInPreferences = ({ goalId, isPremium, onUpgrade }) => {
  const [preferences, setPreferences] = useState({
    enabled: false,
    frequency: 'daily',
    preferredTime: '09:00'
  });
  const [showPreferences, setShowPreferences] = useState(false);
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [message, setMessage] = useState({ text: '', type: '' });
  const [showUpgradeModal, setShowUpgradeModal] = useState(false);

  // Get the auth headers
  const getAuthHeaders = () => {
    const token = localStorage.getItem('token');
    if (!token) {
      throw new Error('No authentication token found');
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`
      }
    };
  };

  useEffect(() => {
    const fetchPreferences = async () => {
      if (!goalId) {
        setLoading(false);
        return;
      }

      try {
        const url = `${API_BASE_URL}/api/goals/${goalId}/check-in-preferences`;
        
        const headers = getAuthHeaders();
        const response = await axios.get(url, headers);
        
        if (response.data && response.data.checkInPreferences) {
          setPreferences(response.data.checkInPreferences);
        } else {
          // Keep default preferences
        }
        setMessage({ text: '', type: '' }); // Clear any existing error messages
      } catch (error) {
        console.error('Error fetching preferences:', {
          status: error.response?.status,
          data: error.response?.data,
          message: error.message,
          url: `${API_BASE_URL}/api/goals/${goalId}/check-in-preferences`
        });
        
        if (error.response?.status === 404) {
          // Don't show error message for 404
        } else {
          setMessage({ 
            text: 'Could not load check-in preferences. Please try again later.', 
            type: 'error' 
          });
        }
      } finally {
        setLoading(false);
      }
    };

    fetchPreferences();
  }, [goalId]);

  const handleSave = async () => {
    if (!isPremium) {
      setShowUpgradeModal(true);
      return;
    }

    setSaving(true);
    try {
      const url = `${API_BASE_URL}/api/goals/${goalId}/check-in-preferences`;
      await axios.put(url, preferences, getAuthHeaders());
      
      setMessage({ 
        text: 'Check-in preferences updated successfully', 
        type: 'success' 
      });
      
      setShowPreferences(false);
      
      setTimeout(() => {
        setMessage({ text: '', type: '' });
      }, 3000);
    } catch (error) {
      setMessage({ 
        text: 'Failed to update check-in preferences', 
        type: 'error' 
      });
    } finally {
      setSaving(false);
    }
  };

  const handleToggle = async (checked) => {
    if (!isPremium && checked) {
      setShowUpgradeModal(true);
      return;
    }

    setSaving(true);
    try {
      const newPreferences = {
        ...preferences,
        enabled: checked
      };
      
      await axios.put(
        `${API_BASE_URL}/api/goals/${goalId}/check-in-preferences`,
        newPreferences,
        getAuthHeaders()
      );
      
      setPreferences(newPreferences);
      setShowPreferences(checked);
      
      setMessage({ 
        text: `Check-ins ${checked ? 'enabled' : 'disabled'} successfully`, 
        type: 'success' 
      });
      
      setTimeout(() => {
        setMessage({ text: '', type: '' });
      }, 3000);
    } catch (error) {
      setMessage({ 
        text: 'Failed to update preferences', 
        type: 'error' 
      });
    } finally {
      setSaving(false);
    }
  };

  const handleTestEmail = async () => {
    if (!isPremium) {
      setShowUpgradeModal(true);
      return;
    }

    try {
      await axios.post(
        `${API_BASE_URL}/api/goals/${goalId}/trigger-check-in`,
        {},
        getAuthHeaders()
      );
      
      setMessage({ 
        text: 'Test check-in email sent successfully', 
        type: 'success' 
      });
      
      setTimeout(() => {
        setMessage({ text: '', type: '' });
      }, 3000);
    } catch (error) {
      setMessage({ 
        text: 'Failed to send test check-in email', 
        type: 'error' 
      });
    }
  };

  const handleUpgradeClick = () => {
    setShowUpgradeModal(false);
    onUpgrade();
  };

  if (!goalId) return null;

  if (loading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex items-center justify-center space-x-2">
          <FontAwesomeIcon icon={faSpinner} className="animate-spin text-blue-600" />
          <span className="text-gray-600">Loading preferences...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-8">
      <div className="flex items-center space-x-3 mb-4">
        <FontAwesomeIcon icon={faBell} className="text-blue-500" />
        <h2 className="text-xl font-bold text-gray-800">Goal Check-ins</h2>
      </div>
      
      {message.text && (
        <div 
          className={`mb-6 p-4 rounded-lg ${
            message.type === 'error' 
              ? 'bg-red-50 text-red-700 border border-red-200' 
              : 'bg-green-50 text-green-700 border border-green-200'
          }`}
        >
          {message.text}
        </div>
      )}

      <div className="space-y-6">
        {/* Toggle Section */}
        <div 
          className={`flex items-center justify-between bg-gray-50 p-4 rounded-lg ${preferences.enabled ? 'cursor-pointer' : ''}`}
          onClick={() => preferences.enabled && setShowPreferences(!showPreferences)}
        >
          <div className="space-y-1">
            <div className="flex items-center space-x-2">
              <FontAwesomeIcon icon={faEnvelope} className="text-blue-500" />
              <span className="font-medium text-gray-800">Email Check-ins</span>
              {!isPremium && (
                <span className="ml-2 inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
                  Premium
                </span>
              )}
            </div>
            <p className="text-sm text-gray-600">
              {preferences.enabled 
                ? `${preferences.frequency} updates at ${preferences.preferredTime}`
                : 'Receive progress updates, reminders, and personalized motivation'}
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <label className="relative inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                className="sr-only peer"
                checked={preferences.enabled}
                onChange={(e) => handleToggle(e.target.checked)}
                disabled={saving}
              />
              <div className="w-14 h-7 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rounded-full peer peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:start-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-6 after:w-6 after:transition-all peer-checked:bg-blue-600"></div>
            </label>
            {preferences.enabled && (
              <FontAwesomeIcon 
                icon={showPreferences ? faChevronUp : faChevronDown} 
                className="text-gray-400"
              />
            )}
          </div>
        </div>

        {/* Preferences Section */}
        {preferences.enabled && showPreferences && (
          <div className="space-y-6 bg-gray-50 p-4 rounded-lg">
            {/* Frequency Selection */}
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faClock} className="text-blue-500" />
                <label className="font-medium text-gray-800">Check-in Frequency</label>
              </div>
              <select
                value={preferences.frequency}
                onChange={(e) => setPreferences(prev => ({ ...prev, frequency: e.target.value }))}
                className="w-full p-2 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
              >
                <option value="daily">Daily Updates</option>
                <option value="weekly">Weekly Summary</option>
              </select>
            </div>

            {/* Time Selection */}
            <div className="space-y-2">
              <div className="flex items-center space-x-2">
                <FontAwesomeIcon icon={faClock} className="text-blue-500" />
                <label className="font-medium text-gray-800">Preferred Time</label>
              </div>
              <input
                type="time"
                value={preferences.preferredTime}
                onChange={(e) => setPreferences(prev => ({ ...prev, preferredTime: e.target.value }))}
                className="w-full p-2 rounded-lg border-gray-300 shadow-sm focus:border-blue-500 focus:ring-blue-500 text-sm"
              />
              <p className="text-sm text-gray-500">All times are in your local timezone</p>
            </div>

            {/* Action Buttons */}
            <div className="flex justify-end space-x-4 pt-4">
              {/* <button
                onClick={handleTestEmail}
                disabled={saving}
                className="px-4 py-2 border border-gray-300 rounded-lg text-sm font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 shadow-sm"
              >
                <FontAwesomeIcon icon={faEnvelope} className="mr-2" />
                Send Test Email
              </button> */}
              <button
                onClick={handleSave}
                disabled={saving}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg text-sm font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 disabled:opacity-50 shadow-sm"
              >
                {saving ? (
                  <>
                    <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                    Saving...
                  </>
                ) : 'Save Changes'}
              </button>
            </div>
          </div>
        )}
      </div>

      {/* Upgrade Modal */}
      <UpgradeModal
        isOpen={showUpgradeModal}
        onClose={() => setShowUpgradeModal(false)}
        onUpgrade={handleUpgradeClick}
        triggerFeature="check-ins"
      />
    </div>
  );
};

export default GoalCheckInPreferences;