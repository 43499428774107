import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import PricingPage from './pages/PricingPage';
import LoginPage from './pages/LoginPage';
import SignUpPage from './pages/SignUpPage';
import OnboardingPage from './pages/OnboardingPage';
import DashboardPage from './pages/DashboardPage';
import AccountDetailsPage from './pages/AccountDetailsPage';
import PrivateRoute from './components/PrivateRoute';
import CreateGoal from './components/CreateGoal';
import GoalDetailsPage from './pages/GoalDetailsPage';
import SuccessPage from './pages/SuccessPage';
import TermsPage from './pages/TermsPage';
import PrivacyPage from './pages/PrivacyPage';
import PaymentPage from './pages/PaymentPage';
import { startKeepAlive } from './utils/keepAlive';
import { AuthProvider } from './AuthContext';
import Analytics from './components/Analytics';

const ScrollToTop = () => {
  const { pathname } = useLocation();
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

const App = () => {
  useEffect(() => {
    const cleanup = startKeepAlive();
    return cleanup;
  }, []);

  return (
    <AuthProvider>
      <Router>
        <Analytics />
        <div className="flex flex-col min-h-screen">
          <ScrollToTop /> 
          <Header />
          <main className="flex-grow container bg-gray-50 mx-auto">
            <Routes>
              <Route path="/" element={<HomePage />} />
              <Route path="/pricing" element={<PricingPage />} />
              <Route path="/login" element={<LoginPage />} />
              <Route path="/signup" element={<SignUpPage />} />
              <Route 
                path="/payment" 
                element={
                  <PrivateRoute>
                    <PaymentPage />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/onboarding" 
                element={
                  <PrivateRoute>
                    <OnboardingPage />
                  </PrivateRoute>
                } 
              />
              <Route 
                path="/dashboard" 
                element={
                  <PrivateRoute>
                    <DashboardPage />
                  </PrivateRoute>
                } 
              />
              <Route path="/create-goal" element={<CreateGoal />} />
              <Route path="/goals/:id" element={<GoalDetailsPage />} />
              <Route path="/payments/success" element={<SuccessPage />} />
              <Route 
                path="/account" 
                element={
                  <PrivateRoute>
                    <AccountDetailsPage />
                  </PrivateRoute>
                } 
              />
              <Route path="/terms" element={<TermsPage />} />
              <Route path="/privacy" element={<PrivacyPage />} />
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </AuthProvider>
  );
};

export default App;