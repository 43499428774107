import React from 'react';
import { Link } from 'react-router-dom';

const PrivacyPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-8 pb-16 md:pt-20 md:pb-28 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg px-6 py-8">
          <div className="flex items-center space-x-4 mb-6">
            <Link 
              to="/" 
              className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to Home
            </Link>
            <span className="text-gray-300">|</span>
            <Link 
              to="/signup" 
              className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Continue to Signup
            </Link>
          </div>
          
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Privacy Policy</h1>
          
          <div className="prose max-w-none space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900">1. Information We Collect</h2>
              <p className="text-gray-600">
                When you sign up for GoalMentorAI, we collect personal information, such as your 
                name, email address, and any other details you provide during the onboarding 
                process. We also collect usage data as you interact with the app, including goals 
                set, progress tracking, and feedback requests.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">2. How We Use Your Information</h2>
              <p className="text-gray-600">
                The data collected is used to personalize your experience with GoalMentorAI and 
                provide tailored goal recommendations and feedback. We may also use anonymized 
                data to improve the AI and app functionality.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">3. Data Sharing</h2>
              <p className="text-gray-600">
                We do not sell or share your personal information with third parties except as 
                required by law or to protect our legal rights. 
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">4. Security</h2>
              <p className="text-gray-600">
                We take reasonable measures to protect your personal information from unauthorized 
                access, disclosure, alteration, or destruction. However, no method of data 
                transmission or storage is completely secure, and we cannot guarantee the security 
                of your information.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">5. Third-Party Services</h2>
              <p className="text-gray-600">
                GoalMentorAI may integrate with third-party services (e.g., payment processors). 
                These services have their own privacy policies, and we encourage you to review 
                them. GoalMentorAI is not responsible for the privacy practices or content of 
                third-party services.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">6. Data Retention</h2>
              <p className="text-gray-600">
                We retain your personal information for as long as your account is active or as 
                needed to provide services. If you request account deletion, we will delete your 
                data, but some information may remain in backups for a limited time.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">7. Your Rights</h2>
              <p className="text-gray-600">
                You have the right to access, correct, or delete your personal information at any 
                time by contacting us at support@goalmentorai.com. You may also opt out of marketing 
                communications by following the unsubscribe instructions in emails.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">8. Changes to this Policy</h2>
              <p className="text-gray-600">
                We reserve the right to update this Privacy Policy at any time. If significant 
                changes are made, we will notify you through the app or via email. Continued use 
                of the app after changes take effect constitutes your acceptance of the updated 
                Privacy Policy.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">9. Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions or concerns regarding this Privacy Policy, please 
                contact us at support@goalmentorai.com.
              </p>
            </section>
          </div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="flex justify-end space-x-4">
              <Link 
                to="/" 
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                Back to Home
              </Link>
              <Link 
                to="/signup" 
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
              >
                Continue to Signup
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPage;