import React from 'react';
import GoalStepBase from './GoalStepBase';

const GoalAchievableStep = ({ onNext, onPrevious, goalData, setGoalData }) => (
  <GoalStepBase
    title="Make it Achievable"
    description="Assess your resources and capabilities to ensure your goal is realistic."
    fieldName="achievable"
    value={goalData.achievable}
    onChange={(value) => setGoalData({ ...goalData, achievable: value })}
    onNext={onNext}
    onPrevious={onPrevious}
    placeholder="ex. I have a laptop and took some music lessons in high school."
    labelText="What relevant resources and skills do you have?"
  />
);

export default GoalAchievableStep;