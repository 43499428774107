import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const GoalTimeboundStep = ({ onNext, onPrevious, goalData }) => {
  const [error, setError] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    // Create date at noon to avoid timezone issues
    const selectedDate = new Date(formData.get('timebound') + 'T12:00:00');
    const oneWeekFromToday = new Date();
    oneWeekFromToday.setDate(oneWeekFromToday.getDate() + 6);

    if (selectedDate < oneWeekFromToday) {
      setError('Please select a date at least one week in the future.');
      return;
    }

    setError('');
    // Store the ISO string with the time set to noon
    onNext({ timebound: selectedDate.toISOString() });
  };

  const minDate = (() => {
    const date = new Date();
    date.setDate(date.getDate() + 7);
    return date.toISOString().split('T')[0];
  })();

  // If we have existing data, format it back to YYYY-MM-DD for the input
  const defaultValue = goalData.timebound 
    ? new Date(goalData.timebound).toISOString().split('T')[0]
    : minDate;

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Make it Time-bound</h2>
        <p className="mt-2 text-gray-600">Set a clear deadline to keep your goal focused and urgent.</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
          <div className="flex items-center mb-3">
            <FontAwesomeIcon icon={faCalendar} className="text-blue-500 mr-2" />
            <label 
              htmlFor="timebound" 
              className="text-sm font-medium text-blue-600 uppercase tracking-wider"
            >
              When do you want to achieve this goal?
            </label>
          </div>

          <div className="relative">
            <input
              type="date"
              id="timebound"
              name="timebound"
              className="w-full px-4 py-3 text-gray-700 bg-white border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 hover:border-blue-300"
              defaultValue={defaultValue}
              min={minDate}
              required
            />
          </div>

          {error ? (
            <p className="mt-2 text-sm text-red-500 animate-fadeIn">
              {error}
            </p>
          ) : (
            <p className="mt-2 text-sm text-gray-500">
              Please select a date at least one week in the future
            </p>
          )}
        </div>

        <div className="flex flex-col sm:flex-row justify-between gap-4">
          <button
            type="button"
            onClick={onPrevious}
            className="group w-full sm:w-auto px-6 py-3 text-sm font-semibold text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-all duration-200 transform hover:-translate-x-0.5"
          >
            <FontAwesomeIcon 
              icon={faArrowLeft} 
              className="mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" 
            />
            Previous
          </button>
          <button
            type="submit"
            className="group w-full sm:w-auto px-6 py-3 text-sm font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-all duration-200 transform hover:translate-x-0.5"
          >
            Next
            <FontAwesomeIcon 
              icon={faArrowRight} 
              className="ml-2 transform group-hover:translate-x-1 transition-transform duration-200" 
            />
          </button>
        </div>
      </form>
    </div>
  );
};

// Update the formatDate function in GoalReviewStep
const formatDate = (dateString) => {
  if (!dateString) return 'Not specified';
  
  // Create date object at noon to avoid timezone issues
  const date = new Date(dateString);
  
  // Use UTC methods to ensure consistent date
  return new Date(Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    12, 0, 0
  )).toLocaleDateString(undefined, {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};

export default GoalTimeboundStep;