import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar, faChartBar, faClock } from '@fortawesome/free-solid-svg-icons';

const GoalCard = ({ goal, onClick }) => {
  const formatDate = (dateString) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  const calculateProgress = () => {
    const totalSubtasks = goal.gamePlan.phases.reduce(
      (total, phase) =>
        total + phase.tasks.reduce((phaseTotal, task) => phaseTotal + (task.subtasks?.length || 0), 0),
      0
    );
    const completedSubtasksCount = goal.completedSubtasks?.length || 0;
    return totalSubtasks === 0 ? 0 : Math.round((completedSubtasksCount / totalSubtasks) * 100);
  };

  const progress = calculateProgress();
  const daysLeft = Math.ceil((new Date(goal.timebound) - new Date()) / (1000 * 60 * 60 * 24));
  const isOverdue = daysLeft < 0;
  const progressColor = progress >= 75 ? 'bg-green-500' : progress >= 50 ? 'bg-blue-500' : 'bg-yellow-500';

  return (
    <div 
      onClick={onClick}
      className="bg-white rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:-translate-y-1 cursor-pointer"
    >
      <div className="p-6">
        <div className="flex items-start justify-between mb-4">
          <h3 className="text-xl font-bold text-gray-900 leading-tight">
            {goal.name || goal.specific || 'Untitled Goal'}
          </h3>
          <span className={`px-3 py-1 rounded-full text-sm font-medium ${
            isOverdue ? 'bg-red-100 text-red-800' : 'bg-blue-100 text-blue-800'
          }`}>
            {isOverdue ? 'Overdue' : `${daysLeft} days left`}
          </span>
        </div>

        <p className="text-gray-600 mb-6 line-clamp-2">
          {goal.description || 'No description available'}
        </p>

        <div className="space-y-4">
          <div className="flex items-center text-sm text-gray-500">
            <FontAwesomeIcon icon={faCalendar} className="mr-2" />
            <span>Due: {formatDate(goal.timebound)}</span>
          </div>

          <div>
            <div className="flex justify-between items-center mb-2">
              <span className="text-sm font-medium text-gray-700">Progress</span>
              <span className="text-sm font-medium text-gray-700">{progress}%</span>
            </div>
            <div className="h-2 bg-gray-200 rounded-full overflow-hidden">
              <div 
                className={`h-full ${progressColor} transition-all duration-500 ease-out`}
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          <div className="flex justify-between items-center pt-2">
            <div className="flex items-center text-sm text-gray-500">
              <FontAwesomeIcon icon={faChartBar} className="mr-2" />
              <span>{goal.hours} hrs/week</span>
            </div>
            <span className="text-blue-500 font-medium group-hover:translate-x-1 transition-transform duration-300">
              View Details →
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GoalCard;