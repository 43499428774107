import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faRobot, faTrophy, faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const Step = ({ icon, title, description, details }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const iconColors = {
    [faCheckCircle]: 'text-green-500',
    [faRobot]: 'text-blue-500',
    [faTrophy]: 'text-yellow-500',
  };

  return (
    <div className="bg-white p-8 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105">
      <div className="flex items-center space-x-4 mb-6">
        <div className="bg-gray-50 p-3 rounded-full">
          <FontAwesomeIcon 
            icon={icon} 
            className={`text-4xl ${iconColors[icon]}`} 
          />
        </div>
        <h3 className="text-2xl font-bold text-gray-800">{title}</h3>
      </div>
      <p className="text-lg text-gray-600 mb-6">{description}</p>
      <button
        className="w-full text-blue-500 font-semibold flex items-center justify-between p-2 rounded-lg hover:bg-blue-50 transition-colors duration-300 focus:outline-none"
        onClick={() => setIsExpanded(!isExpanded)}
      >
        <span>{isExpanded ? 'Show Less' : 'Learn More'}</span>
        <FontAwesomeIcon 
          icon={isExpanded ? faChevronUp : faChevronDown} 
          className={`ml-2 transition-transform duration-300 ${isExpanded ? 'rotate-180' : ''}`} 
        />
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${
        isExpanded ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
      }`}>
        <ul className="mt-4 text-lg text-gray-600 space-y-3 pl-4">
          {details.map((detail, index) => (
            <li key={index} className="flex items-start">
              <span className="text-blue-500 mr-2">•</span>
              {detail}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const Steps = () => {
  const steps = [
    {
      icon: faCheckCircle,
      title: "Set Your SMART Goal",
      description: "Enter your goal using our Specific, Measurable, Achievable, Relevant, and Time-bound framework.",
      details: [
        "Your AI mentor guides you through creating a clear, well-defined goal",
        "Set realistic timelines and milestones",
        "Define how you'll measure progress and success"
      ]
    },
    {
      icon: faRobot,
      title: "AI Crafts Your Plan",
      description: "Our advanced AI analyzes your goal and creates a customized, step-by-step plan to help you achieve it.",
      details: [
        "Considers your goal's complexity, timeline, and your available time",
        "Generates a detailed game plan with phases and actionable tasks",
        "Provides daily task breakdowns and scheduling suggestions",
        "Recommends resources and tools to support your journey"
      ]
    },
    {
      icon: faTrophy,
      title: "Execute and Succeed",
      description: "Follow your plan with the help of your AI mentor, track your progress, and adapt as needed to achieve your goal.",
      details: [
        "Access your personalized dashboard to view tasks and track progress",
        "Receive motivation, advice, and reminders",
        "AI adjusts your plan based on your progress and feedback",
        "Celebrate milestones and ultimately achieve your goal"
      ]
    }
  ];

  return (
    <section id="how-it-works" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="space-y-12">
          <h2 className="text-3xl lg:text-4xl tracking-tight font-bold text-center">
            In Just <span className="text-blue-500">3 Simple Steps</span>
          </h2>
          <div className="grid gap-8 lg:gap-12 lg:grid-cols-3">
            {steps.map((step, index) => (
              <Step key={index} {...step} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Steps;