import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteRight } from '@fortawesome/free-solid-svg-icons';

const Testimonial = ({ content, name, image }) => (
  <div className="flex-shrink-0 w-96 mx-3 first:ml-4 last:mr-4 p-6 rounded-lg border border-gray-200 shadow-md hover:shadow-lg transition-all duration-300 transform hover:scale-105 bg-white">
    <div className="mb-4 flex justify-between items-center">
      <span className="text-yellow-400">
        {"⭐".repeat(5)}
      </span>
      <FontAwesomeIcon icon={faQuoteRight} className="text-blue-500 text-2xl opacity-50" />
    </div>
    <p className="text-gray-600 text-lg mb-6">{content}</p>
    <div className="flex items-center">
      <img src={image} alt={name} className="w-12 h-12 rounded-full mr-4 shadow-md" />
      <p className="font-bold text-gray-800">{name}</p>
    </div>
  </div>
);

const Testimonials = () => {
  const testimonials = [
    {
      content: "GoalMentorAI has been a life-changer for me. It helped me stay focused and motivated to achieve my dream of launching my own business. The personalized action plans and daily check-ins are truly invaluable!",
      name: "John Thatcher",
      image: "/images/user1.jpg"
    },
    {
      content: "I've always been a procrastinator, but GoalMentorAI changed that. The app helped me break down my goal of writing a book into manageable daily tasks. With the motivation and progress tracking, I finished my first draft in just 2 months!",
      name: "Adriana Nakamura",
      image: "/images/user2.jpg"
    },
    {
      content: "I used GoalMentorAI to learn Italian before my trip to Rome. The app created a personalized learning plan based on my timeline and daily availability. The AI mentor made learning a new language fun and achievable!",
      name: "Luca Ricci",
      image: "/images/user3.jpg"
    },
    {
      content: "As a college student, balancing studies and training for a marathon felt impossible. GoalMentorAI created a realistic training plan and provided daily support and motivation. Thanks to my mentor's support, I finished my first marathon!",
      name: "Emily Quader",
      image: "/images/user5.jpg"
    },
    {
      content: "GoalMentorAI has been instrumental in helping me stay committed to my daily mindfulness practice. The personalized reminders and encouragement from the AI mentor make all the difference.",
      name: "Olivia Ramirez",
      image: "/images/user4.jpg"
    },
    {
      content: "As an indie artist, GoalMentorAI helped me build a marketing plan for my release, breaking down tasks like social media promotion and playlist outreach. With its guidance, my streams increased and my audience grew!",
      name: "Noah Jones",
      image: "/images/user6.jpg"
    },
    {
      content: "As a busy mom, planning my trip to Japan felt overwhelming. GoalMentorAI helped me organize everything, from booking flights to creating my itinerary. With the AI's support, I was able to plan stress-free and enjoy every moment!",
      name: "Sophia Nguyen",
      image: "/images/user7.jpg"
    },
    {
      content: "GoalMentorAI guided me through transitioning into a new career in tech. The structured learning path and daily practice reminders helped me land my first role in just 6 months!",
      name: "Aria Avedi",
      image: "/images/user12.jpg"
    },
    {
      content: "I used GoalMentorAI to learn guitar, something I've always wanted to do. The app created a personalized practice plan and provided great resources and feedback. The support and encouragement kept me motivated, and now I can play my favorite songs!",
      name: "Brent Edwards",
      image: "/images/user9.jpg"
    },
    {
      content: "GoalMentorAI turned wedding planning from impossible to manageable with organized tasks and helpful reminders. The AI mentor's daily check-ins kept me organized and on track for my big day!",
      name: "Amelia Costa",
      image: "/images/user10.jpg"
    },
    {
      content: "I use GoalMentorAI to get my finances in order. The app created a personalized plan based on my income level and goals. The AI mentor's daily motivation and progress tracking helps me stay committed to achieving my best!",
      name: "Liam Patel",
      image: "/images/user11.jpg"
    },
    {
      content: "GoalMentorAI helped me quit smoking after years of trying. The app's personalized plan, daily reminders, and AI mentor support kept me motivated and accountable. I've been smoke-free for 6 months now, and I feel amazing!",
      name: "Peter Mikhail",
      image: "/images/user8.jpg"
    },
  ];

  return (
    <section id="testimonials" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2 className="text-3xl lg:text-4xl tracking-tight font-bold mb-12 text-center">
          What Our <span className="text-blue-500">Users Say</span>
        </h2>
        <div className="relative">
          {/* Updated gradient overlays */}
          <div className="absolute inset-y-0 right-0 bg-gradient-to-l from-gray-50 via-gray-50 to-transparent w-12 z-10"></div>
          <div className="absolute inset-y-0 left-0 bg-gradient-to-r from-gray-50 via-gray-50 to-transparent w-12 z-10"></div>
          
          {/* Removed padding from scroll container */}
          <div className="flex overflow-x-auto scrollbar-hidden">
            {testimonials.map((testimonial, index) => (
              <Testimonial key={index} {...testimonial} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Testimonials;