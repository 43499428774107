import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBrain, faLightbulb, faPuzzlePiece, faCheckDouble } from '@fortawesome/free-solid-svg-icons';

const LoadingSpinner = () => {
  const [loadingText, setLoadingText] = useState('Analyzing your SMART goal...');
  const [progress, setProgress] = useState(0);
  const [currentStep, setCurrentStep] = useState(0);

  const steps = [
    {
      text: 'Analyzing your SMART goal...',
      icon: faBrain,
      color: 'text-blue-500'
    },
    {
      text: 'Generating creative ideas...',
      icon: faLightbulb,
      color: 'text-yellow-500'
    },
    {
      text: 'Crafting a personalized plan...',
      icon: faPuzzlePiece,
      color: 'text-green-500'
    },
    {
      text: 'Finalizing your game plan...',
      icon: faCheckDouble,
      color: 'text-purple-500'
    }
  ];

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentStep(prevStep => {
        const nextStep = (prevStep + 1) % steps.length;
        setLoadingText(steps[nextStep].text);
        setProgress(Math.min((nextStep + 1) * 25, 100));
        return nextStep;
      });
    }, 3000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center backdrop-blur-sm">
      <div className="absolute inset-0 bg-gray-900 opacity-90" />
      
      <div className="relative max-w-2xl w-full mx-auto px-4">
        <div className="text-center">
          {/* Spinner */}
          <div className="relative w-24 h-24 mx-auto mb-8">
            {/* Outer rotating ring */}
            <div className="absolute inset-0 rounded-full border-4 border-blue-500/30 animate-[spin_3s_linear_infinite]" />
            
            {/* Inner spinning circle */}
            <div className="absolute inset-2 rounded-full border-4 border-t-blue-500 border-r-transparent border-b-transparent border-l-transparent animate-[spin_1.5s_linear_infinite]" />
            
            {/* Center icon */}
            <div className="absolute inset-0 flex items-center justify-center">
              <FontAwesomeIcon 
                icon={steps[currentStep].icon} 
                className={`w-8 h-8 ${steps[currentStep].color} animate-pulse`} 
              />
            </div>
          </div>

          {/* Title with glowing effect */}
          <h2 className="text-3xl font-bold text-white mb-6 animate-pulse">
            Creating Your{' '}
            <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-blue-600">
              Goal Mentorship Plan
            </span>
          </h2>

          {/* Loading Text with Fade Effect */}
          <div className="h-12 mb-8">
            <p className="text-lg text-blue-100 animate-fade-in">
              {loadingText}
            </p>
          </div>

          {/* Progress Steps */}
          <div className="max-w-md mx-auto mb-8">
            <div className="flex justify-between mb-2">
              {steps.map((step, index) => (
                <div 
                  key={index}
                  className={`w-8 h-8 rounded-full flex items-center justify-center transition-all duration-300 ${
                    index <= currentStep 
                      ? `${steps[index].color} bg-opacity-20` 
                      : 'bg-gray-700'
                  }`}
                >
                  <FontAwesomeIcon 
                    icon={step.icon} 
                    className={`w-4 h-4 ${
                      index <= currentStep 
                        ? steps[index].color 
                        : 'text-gray-500'
                    } transition-colors duration-300`}
                  />
                </div>
              ))}
            </div>

            {/* Progress Bar */}
            <div className="bg-gray-700 rounded-full h-2 overflow-hidden">
              <div 
                className="h-full rounded-full transition-all duration-500 ease-out bg-gradient-to-r from-blue-500 to-blue-600"
                style={{ width: `${progress}%` }}
              />
            </div>
          </div>

          {/* Footer Text with Shimmer Effect */}
          <div className="relative">
            <p className="text-sm text-gray-400">
              This may take a few moments. Please don't close this page.
            </p>
            <div className="absolute inset-0 bg-gradient-to-r from-transparent via-white/10 to-transparent animate-shimmer" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LoadingSpinner;