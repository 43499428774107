import React, { useEffect } from 'react';
import Hero from '../components/Hero';
import Steps from '../components/Steps';
import Categories from '../components/Categories';
import Pricing from '../components/Pricing';
import Testimonials from '../components/Testimonials';
import ProblemSolution from '../components/ProblemSolution';
import FAQ from '../components/FAQ';
import CTASection from '../components/CTASection';
import { Link, useLocation } from 'react-router-dom';

const HomePage = () => {

  const location = useLocation();

  useEffect(() => {
    const scrollToSection = (sectionId) => {
      const element = document.getElementById(sectionId);
      if (element) {
        const yOffset = -80; // Adjust this value based on your header height
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    };

    if (location.state && location.state.scrollTo) {
      // Clear the state to prevent scrolling on subsequent renders
      window.history.replaceState({}, document.title);
      
      // Delay scrolling to ensure all components are rendered
      setTimeout(() => {
        scrollToSection(location.state.scrollTo);
      }, 100);
    } else if (location.hash) {
      // Handle direct links with hash
      const sectionId = location.hash.slice(1);
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    }
  }, [location]);

  return (
    <div className="bg-white">
      <main className="container mx-auto py-8">
        <div className="bg-white">
          <Hero />
          <ProblemSolution />
          <Categories />
        </div>
        <Steps />
        <Pricing />
        <Testimonials />
        <FAQ />
        <CTASection />
      </main>
    </div>
  );
};

export default HomePage;