import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import { useStripeCheckout } from '../utils/stripeCheckout';

const PaymentPage = () => {
  const [isProcessing, setIsProcessing] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { user, isLoading } = useAuth();
  const { handleUpgrade } = useStripeCheckout(user?.id, user?.email);

  useEffect(() => {
    if (!isLoading) {
      // If no user or no onboarding, redirect to appropriate step
      if (!user) {
        navigate('/signup');
        return;
      }
      
      if (!user.onboardingCompleted) {
        navigate('/onboarding?premium=true');
        return;
      }

      // If already premium, go to dashboard
      if (user.planTier === 'premium') {
        navigate('/dashboard');
        return;
      }
    }
  }, [user, isLoading, navigate]);

  const handlePayment = async () => {
    // Double check we have user data before proceeding
    if (!user?.id || !user?.email) {
      setError('Unable to process payment. Please try logging in again.');
      return;
    }

    setIsProcessing(true);
    try {
      await handleUpgrade();
    } catch (err) {
      setError('An error occurred during payment processing. Please try again.');
      setIsProcessing(false);
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="p-8 rounded-lg flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
          <p className="mt-4 text-gray-600 font-medium">Loading your account...</p>
        </div>
      </div>
    );
  }

  if (!user) {
    return null; // The useEffect will handle redirect
  }

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-start pt-12 pb-28 px-4">
      <div className="max-w-3xl mx-auto w-full">
        {/* Header Section */}
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold text-gray-900 mb-3">
            Great job completing your profile! 
          </h1>
          <p className="text-lg text-gray-600 max-w-2xl mx-auto">
            You're just one step away from unlocking all premium features.
          </p>
        </div>

        <div className="bg-white rounded-lg shadow-lg overflow-hidden">
          {/* Order Summary Section */}
          <div className="border-b border-gray-200 bg-gray-50 p-6">
            <h2 className="text-xl font-semibold text-gray-900 mb-2">Order Summary</h2>
            <div className="flex justify-between items-baseline">
              <div>
                <p className="text-gray-600">Premium Plan</p>
                <p className="text-sm text-gray-500">Billed monthly</p>
              </div>
              <div className="text-2xl font-bold text-gray-900">$9.99</div>
            </div>
          </div>

          {/* Payment Section */}
          <div className="p-6">
            {error && (
              <div className="mb-6 p-4 bg-red-50 border border-red-200 text-red-600 rounded-lg">
                {error}
                {(!user?.id || !user?.email) && (
                  <div className="mt-2">
                    <button 
                      onClick={() => window.location.reload()} 
                      className="text-red-600 underline hover:text-red-700"
                    >
                      Refresh page
                    </button>
                  </div>
                )}
              </div>
            )}

            <div className="mb-6">
              <h3 className="font-medium text-gray-900 mb-3">You'll get immediate access to:</h3>
              <ul className="space-y-3">
                {[
                  'Unlimited goals and progress tracking',
                  'Advanced goal achievement plans',
                  'Unlimited mentor feedback',
                  'Unlimited thoughts & ideas tracking',
                  'Personalized daily or weekly check-ins',
                  'Tailored recommendations',
                  'Dynamic goal task lists',
                  'Priority support'
                ].map((feature, index) => (
                  <li key={index} className="flex items-center text-gray-600">
                    <svg className="w-5 h-5 mr-3 text-green-500 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7"></path>
                    </svg>
                    {feature}
                  </li>
                ))}
              </ul>
            </div>

            <button
              onClick={handlePayment}
              disabled={isProcessing || !user?.id || !user?.email}
              className="w-full bg-blue-500 text-white py-4 px-6 rounded-lg font-medium hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 disabled:opacity-50 disabled:transform-none"
            >
              {isProcessing ? (
                <span className="flex items-center justify-center">
                  <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                  </svg>
                  Processing...
                </span>
              ) : (
                'Get Access'
              )}
            </button>

            <div className="mt-6 space-y-3">
              <div className="flex items-center justify-center text-sm text-gray-500">
                <svg className="w-5 h-5 mr-2 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 15v2m-6 4h12a2 2 0 002-2v-6a2 2 0 00-2-2H6a2 2 0 00-2 2v6a2 2 0 002 2zm10-10V7a4 4 0 00-8 0v4h8z"></path>
                </svg>
                Secure payment processed by Stripe
              </div>
              {/* <div className="flex flex-col items-center text-sm text-gray-500 space-y-1">
                <p>You can cancel your subscription anytime</p>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPage;