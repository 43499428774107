import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Dashboard from '../components/Dashboard';

const DashboardPage = () => {
  const { user, isLoading, fetchUser } = useAuth();
  const navigate = useNavigate();
  const [userLoaded, setUserLoaded] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      if (!user && !isLoading) {
        await fetchUser();
        setUserLoaded(true);
      } else {
        setUserLoaded(true);
      }
    };

    fetchData();
  }, [user, isLoading, fetchUser]);

  useEffect(() => {
    if (!isLoading && !user) {
      navigate('/login');
    }
  }, [isLoading, user, navigate]);

  if (isLoading || !userLoaded) {
    return (
      <div className="min-h-screen bg-gray-50 flex flex-col items-center justify-center">
        <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
        <p className="mt-4 text-gray-600 font-medium">Loading your dashboard...</p>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Dashboard />
    </div>
  );
};


export default DashboardPage;