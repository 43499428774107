import React from 'react';
import { Link } from 'react-router-dom';

const CTASection = () => {
  return (
    <section className="mt-12 mb-16 py-16 bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg overflow-hidden shadow-lg transform transition-all duration-300 sm:px-4 mx-4 md:mx-auto hover:shadow-xl">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="md:w-1/3 flex justify-center items-center">
            <img 
              src="/images/logo-white.png" 
              alt="GoalMentorAI Logo" 
              className="w-48 h-48 object-contain transform transition-all duration-300 hover:scale-105"
            />
          </div>
          <div className="md:w-2/3 text-white text-center md:text-left">
            <h2 className="text-3xl lg:text-4xl tracking-tight font-bold mb-4">
              Your goals are within reach - it's time to grasp them.
            </h2>
            <p className="text-xl mb-8">
              Join thousands of achievers who are turning their dreams into reality with GoalMentorAI. 
            </p>
            <Link
              to="/signup"
              className="bg-white text-blue-500 px-8 py-4 rounded-full text-lg font-semibold shadow-md hover:shadow-lg hover:bg-blue-50 inline-block transform hover:scale-105 transition-all duration-300"
            >
              Begin Your Journey Today
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CTASection;