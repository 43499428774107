import React from 'react';
import GoalStepBase from './GoalStepBase';

const GoalSpecificStep = ({ onNext, goalData, setGoalData }) => (
  <GoalStepBase
    title="Make it Specific"
    description="Define your main goal with clear and precise details."
    fieldName="specific"
    value={goalData.specific}
    onChange={(value) => setGoalData({ ...goalData, specific: value })}
    onNext={onNext}
    placeholder="ex. I want to become a music producer."
    labelText="What is your main goal?"
    showPrevious={false}
  />
);

export default GoalSpecificStep;