import React from 'react';

const ProblemSolution = () => {
  return (
    <section className="py-16 bg-white">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex flex-col md:flex-row items-center gap-8">
          <div className="md:w-1/2">
            <img 
              src="/images/problem-solution.png" 
              alt="AI-powered Goal Setting" 
              className="rounded-lg transition-all duration-300 transform"
            />
          </div>
          <div className="md:w-1/2 md:pl-8">
            <h2 className="text-3xl lg:text-4xl tracking-tight font-bold mb-6">
              Goal Achievement, <span className="text-blue-500">Tailored Just for You</span>
            </h2>
            <p className="mb-4 text-gray-600 text-lg">
              <span className="font-bold">Struggling to stay on track with your goals?</span> Traditional goal-setting methods often lack personalization, provide generic advice, and can't adapt to your evolving life.
            </p>
            <p className="mb-4 text-gray-600 text-lg">
              <span className="font-bold">GoalMentorAI simplifies the process.</span> With our advanced AI, we take the guesswork out of goal planning, so you can focus on the doing.
            </p>
            <p className="mb-4 text-gray-600 text-lg">
              <span className="font-bold">Stop worrying about the plan—just achieve.</span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ProblemSolution;
