import React from 'react';

const PricingCard = ({ title, price, features, popularBadge, buttonText, premium, buttonColor, bgColor, textColor }) => {
  return (
    <div className={`p-8 rounded-lg shadow-md hover:shadow-xl transition-all duration-300 transform hover:scale-105 ${bgColor} ${textColor}`}>
      <div className="mb-6 relative">
        <h3 className="text-2xl font-bold mb-2">{title}</h3>
        {popularBadge && (
          <span className="absolute -top-4 right-0 bg-blue-500 text-white px-4 py-1 rounded-full text-sm font-semibold uppercase tracking-wide shadow-md">
            {popularBadge}
          </span>
        )}
      </div>
      <div className="mb-6">
        <p className="text-4xl font-bold">${price}</p>
        <p className="text-gray-500">per month</p>
      </div>
      <ul className="mb-8 space-y-4">
        {features.map((feature, index) => (
          <li key={index} className="flex items-center">
            <span className="text-green-500 mr-3 flex-shrink-0">✓</span>
            <span className="text-gray-600">{feature}</span>
          </li>
        ))}
      </ul>
      <a 
        href={`/signup${premium ? '?premium=true' : ''}`}
        className={`block w-full text-center ${buttonColor} px-8 py-4 rounded-full text-lg font-semibold transition-all duration-300 shadow-md hover:shadow-lg transform hover:scale-105`}
      >
        {buttonText}
      </a>
    </div>
  );
};

const Pricing = () => {
  const freeFeatures = [
    'Set and track a single goal',
    'Basic goal achievement plans',
    'Limited mentor feedback',
    'Limited thoughts & ideas tracking',
  ];

  const premiumFeatures = [
    'Unlimited goals and progress tracking',
    'Advanced goal achievement plans',
    'Unlimited mentor feedback',
    'Unlimited thoughts & ideas tracking',
    'Personalized daily or weekly check-ins',
    'Tailored recommendations',
    'Dynamic goal task lists',
    'Priority support',
  ];

  return (
    <section id="pricing" className="py-20 bg-gray-50">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="text-center mb-16">
          <h2 className="text-3xl lg:text-4xl tracking-tight font-bold mb-4">
            With Simple, <span className="text-blue-500">Transparent Pricing</span>
          </h2>
          <p className="text-xl text-gray-600">Choose the plan that's right for you and unlock your full potential today.</p>
        </div>
        <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 lg:gap-12 items-start max-w-5xl mx-auto">
          <PricingCard
            title="Free"
            price={0}
            features={freeFeatures}
            buttonText="Get Started"
            premium={false}
            buttonColor="bg-gray-100 hover:bg-gray-200 text-gray-800"
            bgColor="bg-white"
            textColor="text-gray-800"
            popularBadge=""
          />
          <PricingCard
            title="Premium"
            price={9.99}
            features={premiumFeatures}
            buttonText="Get Access"
            premium={true}
            buttonColor="bg-blue-500 hover:bg-blue-600 text-white"
            bgColor="bg-white border-2 border-blue-500"
            textColor="text-gray-800"
            popularBadge="Most Popular"
          />
        </div>
      </div>
    </section>
  );
};

export default Pricing;