import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faExclamationTriangle, 
} from '@fortawesome/free-solid-svg-icons';


const ErrorState = ({ message }) => (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="w-16 h-16 mx-auto mb-4 text-red-500">
          <FontAwesomeIcon 
            icon={faExclamationTriangle} 
            className="w-full h-full" 
          />
        </div>
        <h2 className="text-xl font-bold text-gray-900 mb-2">Oops!</h2>
        <p className="text-gray-600">{message}</p>
      </div>
    </div>
  );

export default ErrorState;