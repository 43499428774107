import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCheck, 
  faArrowLeft, 
  faRocket,
  faBullseye,
  faChartLine,
  faCheckCircle,
  faCrosshairs,
  faClock,
  faHourglassHalf
} from '@fortawesome/free-solid-svg-icons';

const GoalReviewStep = ({ onSubmit, onPrevious, goalData, isSubmitting }) => {
  const ReviewItem = ({ label, content, icon }) => (
    <div className="py-4 border-b border-gray-100 last:border-b-0 hover:bg-gray-50 transition-colors duration-200 rounded-lg px-4">
      <div className="flex items-center mb-2">
        <div className="w-8 h-8 rounded-full bg-blue-100 flex items-center justify-center mr-3">
          <FontAwesomeIcon icon={icon} className="text-blue-500" />
        </div>
        <span className="text-sm font-medium text-blue-600 uppercase tracking-wider">
          {label}
        </span>
      </div>
      <div className="text-gray-700 whitespace-pre-wrap break-words ml-11">
        {content || 'Not specified'}
      </div>
    </div>
  );

  const formatDate = (dateString) => {
    if (!dateString) return 'Not specified';
    return new Date(dateString).toLocaleDateString(undefined, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatHours = (hours) => {
    if (!hours) return 'Not specified';
    return `${hours} ${hours === 1 ? 'hour' : 'hours'} per week`;
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">Review Your Goal</h2>
        <p className="mt-2 text-gray-600">
          Please review your SMART goal details below before submitting. Make sure everything looks correct!
        </p>
      </div>

      <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 mb-8 shadow-sm hover:shadow-md transition-all duration-300">
        <div className="space-y-2">
          <ReviewItem 
            label="Specific Goal" 
            content={goalData.specific} 
            icon={faBullseye} 
          />
          <ReviewItem 
            label="Measurable Criteria" 
            content={goalData.measurable} 
            icon={faChartLine} 
          />
          <ReviewItem 
            label="Achievable Resources" 
            content={goalData.achievable} 
            icon={faCheckCircle} 
          />
          <ReviewItem 
            label="Relevant Purpose" 
            content={goalData.relevant} 
            icon={faCrosshairs} 
          />
          <ReviewItem 
            label="Target Date" 
            content={formatDate(goalData.timebound)} 
            icon={faClock} 
          />
          <ReviewItem 
            label="Time Commitment" 
            content={formatHours(goalData.hours)} 
            icon={faHourglassHalf} 
          />
        </div>
      </div>

      <div className="flex flex-col sm:flex-row justify-between gap-4">
        <button
          type="button"
          onClick={onPrevious}
          disabled={isSubmitting}
          className="group w-full sm:w-1/2 px-6 py-3 text-sm font-semibold text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 transition-all duration-200 transform hover:-translate-x-0.5 disabled:opacity-50 disabled:cursor-not-allowed"
        >
          <FontAwesomeIcon 
            icon={faArrowLeft} 
            className="mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" 
          />
          Back to Previous
        </button>
        <button
          type="button"
          onClick={onSubmit}
          disabled={isSubmitting}
          className="group w-full sm:w-1/2 px-6 py-3 text-sm font-semibold text-white bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg hover:from-blue-600 hover:to-blue-700 transition-all duration-200 transform hover:translate-x-0.5 hover:shadow-md disabled:opacity-50 disabled:cursor-not-allowed"
        >
          {isSubmitting ? (
            <>
              <FontAwesomeIcon 
                icon={faRocket} 
                className="mr-2 animate-bounce" 
              />
              Creating Your Goal Plan...
            </>
          ) : (
            <>
              <FontAwesomeIcon 
                icon={faCheck} 
                className="mr-2 transform group-hover:scale-110 transition-transform duration-200" 
              />
              Create Goal Plan
            </>
          )}
        </button>
      </div>

      {/* Optional help text */}
      <p className="mt-6 text-center text-sm text-gray-500">
        Need to make changes? Use the back button to return to previous steps
      </p>
    </div>
  );
};

export default GoalReviewStep;