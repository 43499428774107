import React from 'react';
import GoalStepBase from './GoalStepBase';

const GoalRelevantStep = ({ onNext, onPrevious, goalData, setGoalData }) => (
  <GoalStepBase
    title="Make it Relevant"
    description="Explain how this goal aligns with your broader life objectives."
    fieldName="relevant"
    value={goalData.relevant}
    onChange={(value) => setGoalData({ ...goalData, relevant: value })}
    onNext={onNext}
    onPrevious={onPrevious}
    placeholder="ex. I love music and I could make a career out of it."
    labelText="How does this goal fit into your bigger picture?"
  />
);

export default GoalRelevantStep;