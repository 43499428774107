import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faSpinner, 
} from '@fortawesome/free-solid-svg-icons';


const LoadingState = () => (
    <div className="min-h-screen flex items-center justify-center">
      <div className="text-center">
        <div className="w-16 h-16 mx-auto mb-4">
          <FontAwesomeIcon 
            icon={faSpinner} 
            className="text-blue-500 w-full h-full animate-spin" 
          />
        </div>
        <p className="text-gray-600 font-medium">Loading your goal details...</p>
      </div>
    </div>
  );

export default LoadingState;