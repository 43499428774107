import React, { useEffect, useState, useCallback } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import api from '../api';
import { useAuth } from '../AuthContext';
import GoalCard from './GoalCard';
import { useStripeCheckout } from '../utils/stripeCheckout';

const Dashboard = () => {
  const [goals, setGoals] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { 
    user, 
    isAuthenticated, 
    isLoading: userLoading, 
    fetchUser 
  } = useAuth();
  const navigate = useNavigate();
  const [quote, setQuote] = useState('');

  const userId = user?.id || user?._id;
  const userEmail = user?.email;


  const { handleUpgrade: stripeUpgrade } = useStripeCheckout(userId, userEmail);

  const getTimeBasedGreeting = useCallback(() => {
    const hour = new Date().getHours();
    if (hour < 12) return "Good morning";
    if (hour < 18) return "Good afternoon";
    return "Good evening";
  }, []);

  const getInspirationalQuote = useCallback(() => {
    const quotes = [
      "The only way to do great work is to love what you do.",
      "Your goals are the road maps that guide you and show you what is possible.",
      "Success is not final, failure is not fatal: it is the courage to continue that counts.",
      "The future belongs to those who believe in the beauty of their dreams.",
      "Don't watch the clock; do what it does. Keep going.",
      "The harder you work for something, the greater you'll feel when you achieve it.",
      "It always seems impossible until it's done.",
      "Start where you are. Use what you have. Do what you can.",
      "Success is the sum of small efforts, repeated day in and day out.",
      "Believe you can and you're halfway there.",
      "Dream big and dare to fail.",
      "The only limit to our realization of tomorrow is our doubts of today.",
      "You don't have to be great to start, but you have to start to be great.",
      "Opportunities don't happen, you create them.",
      "Don't be afraid to give up the good to go for the great.",
      "What you get by achieving your goals is not as important as what you become by achieving your goals.",
      "The secret of getting ahead is getting started.",
      "Your limitation—it's only your imagination.",
      "Success usually comes to those who are too busy to be looking for it.",
      "You miss 100% of the shots you don’t take.",
    ];
    return quotes[Math.floor(Math.random() * quotes.length)];
  }, []);
  
  const fetchGoals = useCallback(async () => {
    setLoading(true);
    try {
      const response = await api.get('/goals');
      setGoals(response.data);
      setError(null);
    } catch (error) {
      setError('Failed to fetch goals. Please try again.');
    } finally {
      setLoading(false);
    }
  }, []);

  const getDisplayedGoals = useCallback(() => {
    if (user?.planTier === 'Premium') {
      return goals;
    } else {
      // For Free tier, only show the first goal
      return goals.slice(0, 1);
    }
  }, [goals, user?.planTier]);

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
    } else {
      fetchGoals();
    }
  }, [isAuthenticated, navigate, fetchGoals]);

  useEffect(() => {
    const storedQuote = localStorage.getItem('dailyQuote');
    const storedDate = localStorage.getItem('quoteDate');
    const today = new Date().toDateString();

    if (storedQuote && storedDate === today) {
      setQuote(storedQuote);
    } else {
      const newQuote = getInspirationalQuote();
      setQuote(newQuote);
      localStorage.setItem('dailyQuote', newQuote);
      localStorage.setItem('quoteDate', today);
    }
  }, [getInspirationalQuote]);

  const handleGoalClick = useCallback((goalId) => {
    navigate(`/goals/${goalId}`);
  }, [navigate]);

  const handleUpgrade = useCallback(async () => {
    setError(null);
    
    if (!userEmail) {
      setError('Email is required for upgrade');
      return;
    }

    try {
      await stripeUpgrade();
      await fetchUser();
      await fetchGoals();
    } catch (error) {
      setError(error.message || 'An error occurred during the upgrade process');
    }
  }, [userId, userEmail, stripeUpgrade, fetchUser, fetchGoals]);

  const handleCreateGoal = useCallback(async () => {
    if (user && user.planTier === 'Free' && goals.length >= 1) {
      handleUpgrade();
    } else {
      navigate('/create-goal');
    }
  }, [user, goals.length, handleUpgrade, navigate]);

  if (userLoading) {
    return (
      <div className="flex justify-center items-center min-h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  if (!isAuthenticated) {
    navigate('/login');
    return null;
  }

  const displayedGoals = getDisplayedGoals();

  if (!user) {
    return null;
  }

  return (
    <div className="container mx-auto px-4 pt-8 md:pt-16 pb-28 max-w-7xl">
      {/* Header Section */}
      <div className="bg-white rounded-lg shadow-md p-6 mb-8">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="mb-4 md:mb-0">
            <h1 className="text-3xl md:text-4xl font-bold text-gray-900">
              {getTimeBasedGreeting()}, <span className="text-blue-500">{user.name || user.username || 'goal-crusher'}!</span>
            </h1>
            <p className="text-gray-600 mt-2">Ready to crush your goals today?</p>
          </div>
          <div className="flex gap-4">
            <Link 
              to="/account" 
              className="inline-flex items-center px-6 py-3 rounded-lg border border-gray-300 text-gray-700 bg-white shadow-sm hover:bg-gray-50 transition-all duration-300 font-medium"
            >
              Account Settings
            </Link>
            <button 
              onClick={handleCreateGoal} 
              className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 font-medium"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Create Goal
            </button>
          </div>
        </div>
      </div>

      {/* Inspirational Quote */}
      <div className="bg-gradient-to-r from-blue-500 to-blue-600 rounded-lg shadow-lg p-8 mb-12 text-white">
        <div className="flex items-center">
          <div className="flex-1">
            <p className="text-xl md:text-2xl italic">"{quote}"</p>
            <div className="mt-4 h-1 w-16 bg-white opacity-50 rounded"></div>
          </div>
        </div>
      </div>

      {/* Goals Section */}
      {loading ? (
        <div className="flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-4 border-b-4 border-blue-500"></div>
        </div>
      ) : error ? (
        <div className="bg-red-50 text-red-600 p-4 rounded-lg text-center">
          {error}
        </div>
      ) : displayedGoals.length > 0 ? (
        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-3 gap-8">
          {displayedGoals.map((goal) => (
            <GoalCard key={goal._id} goal={goal} onClick={() => handleGoalClick(goal._id)} />
          ))}
          
          {/* Premium Upgrade Card */}
          {user.planTier === 'Free' && (
            <div className="bg-white rounded-lg shadow-lg p-6 border-2 border-blue-500 hover:shadow-xl transition-all duration-300">
              <div className="flex items-center mb-6">
                <div className="bg-blue-500 rounded-full p-3">
                  <svg className="w-6 h-6 text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 10V3L4 14h7v7l9-11h-7z" />
                  </svg>
                </div>
                <h2 className="text-2xl font-bold text-gray-900 ml-4">Upgrade to Premium</h2>
              </div>

              <div className="space-y-6">
                <p className="text-gray-700 text-lg">
                  {goals.length === 0 ? (
                    "Start your goal-setting journey with unlimited goals! Upgrade now to create and manage multiple goals, and supercharge your progress!"
                  ) : goals.length === 1 ? (
                    "Great start with your first goal! Upgrade now to create unlimited goals and unlock advanced features to accelerate your progress!"
                  ) : (
                    `You have ${goals.length - 1} more goal${goals.length > 2 ? 's' : ''} waiting to be unlocked! Upgrade now to access all your goals, create unlimited new ones, and supercharge your progress!`
                  )}
                </p>

                <ul className="space-y-3">
                  {[
                    'Create unlimited goals',
                    'Unlock advanced goal planning features',
                    'Get personalized AI mentor feedback',
                    'Receive SMS + Email motivation and reminders'
                  ].map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-700">
                      <svg className="w-5 h-5 text-blue-500 mr-3 flex-shrink-0" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
                      </svg>
                      {feature}
                    </li>
                  ))}
                </ul>

                <button
                  onClick={handleUpgrade}
                  disabled={userLoading}
                  className={`w-full ${
                    userLoading ? 'bg-gray-400' : 'bg-blue-500 hover:bg-blue-600'
                  } text-white py-3 px-6 rounded-lg shadow-md transform hover:scale-105 transition-all duration-300 font-medium`}
                >
                  {userLoading ? 'Loading...' : 'Upgrade Now'}
                </button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className="text-center py-12">
          <div className="bg-white rounded-lg shadow-md p-8 max-w-2xl mx-auto">
            <h2 className="text-2xl font-bold text-gray-900 mb-4">Start Your Journey</h2>
            <p className="text-gray-600 mb-6">
              Create your first goal and begin your path to success. Our AI mentor will guide you every step of the way.
            </p>
            <button
              onClick={() => navigate('/create-goal')}
              className="inline-flex items-center px-6 py-3 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 transition-all duration-300 transform hover:scale-105 font-medium"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6" />
              </svg>
              Create Your First Goal
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Dashboard;