import React from 'react';
import { Link } from 'react-router-dom';

const TermsPage = () => {
  return (
    <div className="min-h-screen bg-gray-50 pt-8 pb-16 md:pt-20 md:pb-28 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl mx-auto">
        <div className="bg-white shadow-lg rounded-lg px-6 py-8">
          <div className="flex items-center space-x-4 mb-6">
            <Link 
              to="/" 
              className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M10 19l-7-7m0 0l7-7m-7 7h18" />
              </svg>
              Back to Home
            </Link>
            <span className="text-gray-300">|</span>
            <Link 
              to="/signup" 
              className="inline-flex items-center text-blue-500 hover:text-blue-600 transition-colors"
            >
              <svg className="w-5 h-5 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M12 4v16m8-8H4" />
              </svg>
              Continue to Signup
            </Link>
          </div>
          
          <h1 className="text-3xl font-bold text-gray-900 mb-8">Terms of Service</h1>
          
          <div className="prose max-w-none space-y-8">
            <section>
              <h2 className="text-xl font-semibold text-gray-900">1. Acceptance of Terms</h2>
              <p className="text-gray-600">
                By accessing or using GoalMentorAI, you agree to these Terms of Service.
                If you do not agree to any part of these terms, please do not use the app.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">2. Use of the App</h2>
              <p className="text-gray-600">
                GoalMentorAI is an AI-powered goal-setting and mentorship platform designed 
                to help users create and track personal goals. The app provides recommendations, 
                plans, and guidance based on user input, but users are responsible for their 
                own decisions and goal-setting.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">3. Account Responsibility</h2>
              <p className="text-gray-600">
                You are responsible for maintaining the confidentiality of your account 
                credentials. You agree to notify us immediately if you suspect any unauthorized 
                access to your account. GoalMentorAI is not liable for any loss or damage 
                arising from your failure to protect your account information.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">4. AI Recommendations and User Responsibility</h2>
              <p className="text-gray-600">
                The AI-generated advice, plans, or feedback provided by GoalMentorAI is intended 
                for guidance only. It should not be considered professional advice in areas such 
                as health, finance, legal, or any other regulated field. Always consult with a 
                qualified professional before making decisions based on AI-generated content.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">5. Disclaimer of Liability</h2>
              <p className="text-gray-600">
                GoalMentorAI is provided "as is" without warranties of any kind, express or 
                implied. We do not guarantee the accuracy, completeness, or reliability of the 
                AI's recommendations or the app's functionality. GoalMentorAI is not liable for 
                any direct, indirect, incidental, consequential, or punitive damages, including 
                but not limited to loss of profits, data, or any other damages resulting from 
                the use of the app, security breaches, or reliance on AI-generated recommendations.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">6. Changes to the Service</h2>
              <p className="text-gray-600">
                We reserve the right to modify or discontinue, temporarily or permanently, 
                GoalMentorAI or any part of it without notice. We are not liable for any 
                changes, suspension, or discontinuation of the service.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">7. Termination</h2>
              <p className="text-gray-600">
                We may terminate or suspend your account at our sole discretion if you violate 
                these Terms of Service. Upon termination, you will no longer have access to the 
                app, and any data associated with your account may be deleted.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold text-gray-900">8. Governing Law</h2>
              <p className="text-gray-600">
                These Terms of Service are governed by the laws of the United States of America. Any 
                disputes arising from the use of GoalMentorAI will be resolved under the 
                jurisdiction of the courts of the United States of America.
              </p>
            </section>
          </div>

          <div className="mt-8 pt-6 border-t border-gray-200">
            <div className="flex justify-end space-x-4">
              <Link 
                to="/" 
                className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 transition-colors"
              >
                Back to Home
              </Link>
              <Link 
                to="/signup" 
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 transition-colors"
              >
                Continue to Signup
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TermsPage;