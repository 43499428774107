// KeyMetrics.js
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faCalendarAlt, 
  faClock, 
  faChartLine, 
} from '@fortawesome/free-solid-svg-icons';

const KeyMetrics = ({ goal, completedSubtasks }) => {
  // Calculate total subtasks from game plan
  const calculateTotalSubtasks = () => {
    if (!goal.gamePlan?.phases) return 0;
    
    return goal.gamePlan.phases.reduce((total, phase) => {
      return total + phase.tasks.reduce((phaseTotal, task) => {
        return phaseTotal + (task.subtasks?.length || 0);
      }, 0);
    }, 0);
  };

  const totalSubtasks = calculateTotalSubtasks();
  const completedCount = completedSubtasks?.length || 0;

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6 mb-6">
      {/* Deadline Card */}
      <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300">
        <div className="flex items-center mb-4">
          <div className="w-10 h-10 rounded-full bg-blue-100 flex items-center justify-center mr-3">
            <FontAwesomeIcon icon={faCalendarAlt} className="text-blue-500" />
          </div>
          <h3 className="text-lg font-semibold text-gray-800">Deadline</h3>
        </div>
        <p className="text-2xl font-bold text-blue-600">
          {new Date(goal.timebound).toLocaleDateString(undefined, {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
          })}
        </p>
        <p className="text-sm text-gray-500 mt-2">
          {Math.ceil((new Date(goal.timebound) - new Date()) / (1000 * 60 * 60 * 24))} days remaining
        </p>
      </div>
  
      {/* Weekly Hours Card */}
      <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300">
        <div className="flex items-center mb-4">
          <div className="w-10 h-10 rounded-full bg-green-100 flex items-center justify-center mr-3">
            <FontAwesomeIcon icon={faClock} className="text-green-500" />
          </div>
          <h3 className="text-lg font-semibold text-gray-800">Weekly Commitment</h3>
        </div>
        <p className="text-2xl font-bold text-green-600">
          {goal.hours} hours/week
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Dedicated time for success
        </p>
      </div>
  
      {/* Progress Card */}
      <div className="bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-all duration-300">
        <div className="flex items-center mb-4">
          <div className="w-10 h-10 rounded-full bg-purple-100 flex items-center justify-center mr-3">
            <FontAwesomeIcon icon={faChartLine} className="text-purple-500" />
          </div>
          <h3 className="text-lg font-semibold text-gray-800">Tasks Completed</h3>
        </div>
        <p className="text-2xl font-bold text-purple-600">
          {completedCount}/{totalSubtasks}
        </p>
        <p className="text-sm text-gray-500 mt-2">
          Subtasks finished
        </p>
      </div>
    </div>
  );
};

export default KeyMetrics;