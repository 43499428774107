import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';
import Onboarding from '../components/Onboarding';

const OnboardingPage = () => {
  const { user, isLoading, fetchUser } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [error, setError] = useState(null);

  const isPremiumFlow = new URLSearchParams(location.search).get('premium') === 'true';

  useEffect(() => {
    const checkUserStatus = async () => {
      if (!isLoading) {
        // If no user, redirect to signup
        if (!user) {
          navigate('/signup');
          return;
        }

        // If onboarding is completed...
        if (user?.onboardingCompleted) {
          // For premium flow, redirect to payment if not already premium
          if (isPremiumFlow && user.planTier !== 'premium') {
            navigate('/payment');
            return;
          }
          // Otherwise go to dashboard
          navigate('/dashboard');
        }
      }
    };

    checkUserStatus();
  }, [isLoading, user, navigate, isPremiumFlow]);

  const handleOnboardingComplete = async () => {
    try {
      await fetchUser();
      
      // After onboarding completes, check if this was premium flow
      if (isPremiumFlow) {
        navigate('/payment');
      } else {
        navigate('/dashboard');
      }
    } catch (err) {
      setError('Failed to complete onboarding. Please try again.');
    }
  };

  if (isLoading) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="p-8 rounded-lg flex flex-col items-center">
          <div className="animate-spin rounded-full h-16 w-16 border-t-4 border-b-4 border-blue-500"></div>
          <p className="mt-4 text-gray-600 font-medium">Loading your profile...</p>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="min-h-screen bg-gray-50 flex justify-center items-center">
        <div className="p-8 bg-white rounded-lg shadow-lg">
          <p className="text-red-600 mb-4">{error}</p>
          <button
            onClick={() => window.location.reload()}
            className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600 transition-colors"
          >
            Try Again
          </button>
        </div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      <Onboarding 
        onComplete={handleOnboardingComplete}
        isPremiumFlow={isPremiumFlow}
      />
    </div>
  );
};

export default OnboardingPage;