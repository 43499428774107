const CONFIG = {
  PING_INTERVAL: 14 * 60 * 1000, 
  MAX_RETRIES: 3,
  RETRY_DELAY: 5000, 
};

const addJitter = (delay) => {
  const jitter = Math.random() * 2000 - 1000; // ±1 second
  return delay + jitter;
};

const pingServer = async (retryCount = 0) => {
  try {
    const startTime = Date.now();
    const response = await fetch(`${process.env.REACT_APP_API_URL}/api/health`, {
      // Add timeout to prevent hanging requests
      signal: AbortSignal.timeout(5000)
    });
    
    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }
    
    const duration = Date.now() - startTime;
    console.debug(`[Keep-Alive] Server ping successful (${duration}ms)`);
    
  } catch (error) {
    if (error.name === 'AbortError') {
      console.warn('[Keep-Alive] Ping timeout');
    } else {
      console.warn(`[Keep-Alive] Ping failed: ${error.message}`);
    }
    
    if (retryCount < CONFIG.MAX_RETRIES) {
      const nextRetryDelay = CONFIG.RETRY_DELAY * Math.pow(2, retryCount); // Exponential backoff
      console.debug(`[Keep-Alive] Retrying in ${nextRetryDelay}ms (${retryCount + 1}/${CONFIG.MAX_RETRIES})`);
      setTimeout(() => pingServer(retryCount + 1), nextRetryDelay);
    }
  }
};

export const startKeepAlive = () => {
  let intervalId = null;
  
  // Initial ping with slight delay
  const initialDelay = Math.random() * 1000;
  setTimeout(() => {
    pingServer();
    
    // Start interval with jitter
    intervalId = setInterval(() => {
      pingServer();
    }, addJitter(CONFIG.PING_INTERVAL));
    
  }, initialDelay);
  
  // Return cleanup function
  return () => {
    if (intervalId) {
      clearInterval(intervalId);
      console.debug('[Keep-Alive] Service stopped');
    }
  };
};