import React, { useState, useCallback } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { saveOnboardingData } from '../api';
import { useAuth } from '../AuthContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faCheck } from '@fortawesome/free-solid-svg-icons';

const Onboarding = () => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    name: '',
    ageRange: '',
    occupation: '',
    education: '',
    phoneNumber: ''
  });
  const [errors, setErrors] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { fetchUser } = useAuth();

  // Check if this is a premium flow
  const isPremiumFlow = new URLSearchParams(location.search).get('premium') === 'true';

  const steps = [
    { title: "Personal Info", description: "Tell us your first name" },
    { title: "Age Range", description: "Select your age group" },
    { title: "Occupation", description: "What do you do?" },
    { title: "Contact", description: "How can we reach you?" },
    { title: "Education", description: "Your educational background" }
  ];

  // Validation patterns
  const validationPatterns = {
    name: /^[a-zA-Z\s'-]{2,20}$/,
    phoneNumber: /^\+?[1-9]\d{1,14}$/, 
    occupation: /^[a-zA-Z\s\-&().]{2,30}$/
  };

  // Input sanitization
  const sanitizeInput = (value, type) => {
    if (!value) return '';
    
    switch (type) {
      case 'name':
        return value.trim().replace(/[^a-zA-Z\s'-]/g, '').slice(0, 20);
      case 'phoneNumber':
        return value.replace(/[^\d+\-() ]/g, '').slice(0, 15);
      case 'occupation':
        return value.replace(/[^a-zA-Z\s\-&().]/g, '').slice(0, 30);
      default:
        return value.trim();
    }
  };

  const validateStep = useCallback(() => {
    const errors = {};

    switch (step) {
      case 1: // Name
        if (!formData.name) {
          errors.name = 'Name is required';
        } else if (!validationPatterns.name.test(formData.name)) {
          errors.name = 'Please enter a valid name (2-20 characters, letters, spaces, hyphens and apostrophes only)';
        }
        break;

      case 2: // Age Range
        if (!formData.ageRange) {
          errors.ageRange = 'Please select your age range';
        }
        break;

      case 3: // Occupation (optional)
        if (formData.occupation && !validationPatterns.occupation.test(formData.occupation)) {
          errors.occupation = 'Please enter a valid occupation (2-30 characters)';
        }
        break;

      case 4: // Phone Number - Updated validation
        const cleanedPhoneNumber = formData.phoneNumber.replace(/\s+/g, '');
        if (cleanedPhoneNumber) {
          if (!validationPatterns.phoneNumber.test(cleanedPhoneNumber)) {
            errors.phoneNumber = 'Please enter a valid phone number (e.g., +1234567890)';
          }
          if (cleanedPhoneNumber.length < 7) {
            errors.phoneNumber = 'Phone number is too short';
          }
          if (cleanedPhoneNumber.length > 15) {
            errors.phoneNumber = 'Phone number is too long';
          }
        }
        break;

      case 5: // Education
        if (!formData.education) {
          errors.education = 'Please select your education level';
        }
        break;

      default:
        break;
    }

    return errors;
  }, [step, formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    const sanitizedValue = name === 'occupation' 
      ? value.replace(/[^a-zA-Z\s\-&().]/g, '').slice(0, 30)
      : sanitizeInput(value, name);

    setFormData(prev => ({
      ...prev,
      [name]: sanitizedValue
    }));

    setFormData(prev => ({
      ...prev,
      [name]: sanitizedValue
    }));

    // Added real-time validation for phone number
    if (name === 'phoneNumber') {
      const phoneErrors = {};
      const cleanedPhoneNumber = sanitizedValue.replace(/\s+/g, '');
      
      if (cleanedPhoneNumber) {
        if (!validationPatterns.phoneNumber.test(cleanedPhoneNumber)) {
          phoneErrors.phoneNumber = 'Please enter a valid phone number (e.g., +1234567890)';
        }
        if (cleanedPhoneNumber.length < 7) {
          phoneErrors.phoneNumber = 'Phone number is too short';
        }
        if (cleanedPhoneNumber.length > 15) {
          phoneErrors.phoneNumber = 'Phone number is too long';
        }
      }
      
      setErrors(prev => ({
        ...prev,
        phoneNumber: phoneErrors.phoneNumber || ''
      }));
    } else {
      // Clear error for other fields when user starts typing
      if (errors[name]) {
        setErrors(prev => ({
          ...prev,
          [name]: ''
        }));
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    
    const stepErrors = validateStep();
    if (Object.keys(stepErrors).length > 0) {
      setErrors(stepErrors);
      return;
    }

    if (step < 5) {
      setErrors({});
      setStep(step + 1);
      window.scrollTo(0, 0);
    } else {
      try {
        setIsSubmitting(true);
        setErrors({});

        const sanitizedData = {
          name: sanitizeInput(formData.name, 'name'),
          ageRange: formData.ageRange,
          occupation: sanitizeInput(formData.occupation, 'occupation'),
          phoneNumber: sanitizeInput(formData.phoneNumber, 'phoneNumber').replace(/\s+/g, ''),
          education: formData.education,
          onboardingCompleted: true,
          isPremiumFlow
        };
        
        const response = await saveOnboardingData(sanitizedData);
        
        if (response.message === 'Onboarding data saved successfully') {
          await fetchUser();
          navigate(isPremiumFlow ? '/payment' : '/dashboard');
        } else {
          setErrors({
            general: 'Unexpected response from server. Please try again.'
          });
        }
      } catch (error) {
        setErrors({
          general: error.response?.data?.message || 'An error occurred. Please try again.'
        });
      } finally {
        setIsSubmitting(false);
      }
    }
  };

  const handleBack = () => {
    if (step > 1) {
      setStep(step - 1);
      setErrors({});
    }
  };

  // Render field based on current step
  const renderField = () => {
    switch (step) {
      case 1:
        return (
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-700 font-medium">What's should we call you?</span>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                required
                className={`mt-1 block w-full px-4 py-3 rounded-lg border ${
                  errors.name ? 'border-red-500' : 'border-gray-300'
                } shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
                placeholder="Enter your first name"
              />
              {errors.name && (
                <p className="mt-1 text-sm text-red-500">{errors.name}</p>
              )}
            </label>
          </div>
        );

      case 2:
        return (
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-700 font-medium">What's your age range?</span>
              <select
                name="ageRange"
                value={formData.ageRange}
                onChange={handleChange}
                required
                className={`mt-1 block w-full px-4 py-3 rounded-lg border ${
                  errors.ageRange ? 'border-red-500' : 'border-gray-300'
                } shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
              >
                <option value="">Select your age range</option>
                <option value="18-24">18-24 years old</option>
                <option value="25-34">25-34 years old</option>
                <option value="35-44">35-44 years old</option>
                <option value="45-54">45-54 years old</option>
                <option value="55+">55 years or older</option>
              </select>
              {errors.ageRange && (
                <p className="mt-1 text-sm text-red-500">{errors.ageRange}</p>
              )}
            </label>
          </div>
        );

      case 3:
        return (
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-700 font-medium">What's your occupation?</span>
              <input
                type="text"
                name="occupation"
                value={formData.occupation}
                onChange={handleChange}
                className={`mt-1 block w-full px-4 py-3 rounded-lg border ${
                  errors.occupation ? 'border-red-500' : 'border-gray-300'
                } shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
                placeholder="Enter your current occupation"
              />
              <p className="mt-1 text-sm text-gray-500">Optional</p>
              {errors.occupation && (
                <p className="mt-1 text-sm text-red-500">{errors.occupation}</p>
              )}
            </label>
          </div>
        );

      case 4:
        return (
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-700 font-medium">What's your phone number?</span>
              <input
                type="tel"
                name="phoneNumber"
                value={formData.phoneNumber}
                onChange={handleChange}
                className={`mt-1 block w-full px-4 py-3 rounded-lg border ${
                  errors.phoneNumber ? 'border-red-500' : 'border-gray-300'
                } shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
                placeholder="Enter your phone number"
              />
              <p className="mt-1 text-sm text-gray-500">Optional</p>
              {errors.phoneNumber && (
                <p className="mt-1 text-sm text-red-500">{errors.phoneNumber}</p>
              )}
            </label>
          </div>
        );

      case 5:
        return (
          <div className="space-y-4">
            <label className="block">
              <span className="text-gray-700 font-medium">What's your highest level of education?</span>
              <select
                name="education"
                value={formData.education}
                onChange={handleChange}
                required
                className={`mt-1 block w-full px-4 py-3 rounded-lg border ${
                  errors.education ? 'border-red-500' : 'border-gray-300'
                } shadow-sm focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-colors duration-200`}
              >
                <option value="">Select your education level</option>
                <option value="High School">High School</option>
                <option value="Some College">Some College</option>
                <option value="Associate's Degree">Associate's Degree</option>
                <option value="Bachelor's Degree">Bachelor's Degree</option>
                <option value="Master's Degree">Master's Degree</option>
                <option value="Doctorate">Doctorate</option>
                <option value="Other">Other</option>
              </select>
              {errors.education && (
                <p className="mt-1 text-sm text-red-500">{errors.education}</p>
              )}
            </label>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className="min-h-screen pt-20 pb-28 px-4 sm:px-6 lg:px-8">
      <div className="max-w-md mx-auto">
        <div className="text-center mb-8">
          <img 
            src="/images/logo-blorange.png" 
            alt="GoalMentorAI Logo" 
            className="h-12 mx-auto mb-4"
          />
          <h1 className="text-3xl font-bold text-gray-900 mb-2">
            {steps[step - 1].title}
          </h1>
          <p className="text-lg text-gray-600">
            {steps[step - 1].description}
          </p>
        </div>

        {/* Progress Bar */}
        <div className="mb-8">
          <div className="flex justify-between mb-2">
            {steps.map((s, i) => (
              <div 
                key={i}
                className={`w-8 h-8 rounded-full flex items-center justify-center ${
                  i + 1 === step 
                    ? 'bg-blue-500 text-white' 
                    : i + 1 < step 
                      ? 'bg-green-500 text-white'
                      : 'bg-gray-200 text-gray-500'
                } transition-all duration-300`}
              >
                {i + 1 < step ? (
                  <FontAwesomeIcon icon={faCheck} className="h-4 w-4" />
                ) : (
                  i + 1
                )}
              </div>
            ))}
          </div>
          <div className="h-2 bg-gray-200 rounded-full">
            <div 
              className="h-2 bg-blue-500 rounded-full transition-all duration-500 ease-out"
              style={{ width: `${((step - 1) / 4) * 100}%` }}
            />
          </div>
        </div>

        {/* Form */}
        <div className="bg-white shadow-lg rounded-lg p-8">
          {errors.general && (
            <div className="mb-6 bg-red-50 border border-red-200 text-red-600 px-4 py-3 rounded-lg">
              {errors.general}
            </div>
          )}

          <form onSubmit={handleSubmit} className="space-y-6">
            {renderField()}

            <div className="flex space-x-4 pt-6">
              {step > 1 && (
                <button
                  type="button"
                  onClick={handleBack}
                  className="flex-1 px-4 py-3 border border-gray-300 rounded-lg text-gray-700 font-medium hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-colors duration-300"
                >
                  Back
                </button>
              )}
              <button
                type="submit"
                disabled={isSubmitting}
                className={`flex-1 px-4 py-3 bg-blue-500 text-white rounded-lg font-medium hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-300 transform hover:scale-105 ${
                  isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                }`}
                >
                  {isSubmitting ? (
                    <span className="flex items-center justify-center">
                      <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                      Saving...
                    </span>
                  ) : (
                    step < 5 ? 'Continue' : isPremiumFlow ? 'Continue' : 'Complete Setup'
                  )}
                </button>
              </div>
            </form>
          </div>
  
        </div>
      </div>
    );
  };
  
  export default Onboarding;