import React from 'react';
import SmartGoalWizard from './SmartGoalWizard';

const CreateGoal = () => {
  return (
    <div className="container mx-auto mt-8">
      <SmartGoalWizard />
    </div>
  );
};

export default CreateGoal;