import React, { useState, useRef, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';

const GoalStepBase = ({ 
  title, 
  description, 
  fieldName, 
  value = '', 
  onChange, 
  onNext, 
  onPrevious, 
  placeholder, 
  labelText,
  showPrevious = true,
  autoFocus = true
}) => {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const maxLength = 280; 
  const minLength = 10;
  const inputRef = useRef(null);

  useEffect(() => {
    if (autoFocus && inputRef.current) {
      inputRef.current.focus();
    }
  }, [autoFocus]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const trimmedValue = value.trim();
    
    if (trimmedValue.length < minLength) {
      setShowError(true);
      setErrorMessage(`Please enter at least ${minLength} characters`);
      return;
    }
    
    onNext({ [fieldName]: trimmedValue });
  };

  const handleChange = (e) => {
    const newValue = e.target.value;
    
    if (newValue.length <= maxLength) {
      onChange(newValue);
      setShowError(false);
      setErrorMessage('');
    } else {
      setShowError(true);
      setErrorMessage(`Maximum ${maxLength} characters allowed`);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleSubmit(e);
    }
  };

  const getCharacterCountColor = () => {
    const length = value.length;
    if (length >= maxLength) return 'text-red-500';
    if (length >= maxLength * 0.9) return 'text-orange-500';
    return 'text-gray-500';
  };

  return (
    <div className="w-full max-w-4xl mx-auto">
      <div className="mb-8">
        <h2 className="text-2xl font-bold text-gray-900">{title}</h2>
        <p className="mt-2 text-gray-600">{description}</p>
      </div>

      <form onSubmit={handleSubmit} className="space-y-6">
        <div className="bg-gradient-to-br from-gray-50 to-white rounded-xl p-6 shadow-sm hover:shadow-md transition-all duration-300">
          <label 
            htmlFor={fieldName} 
            className="block mb-3 text-sm font-medium text-blue-600 uppercase tracking-wider"
          >
            {labelText || title}
          </label>
          
          <textarea
            ref={inputRef}
            id={fieldName}
            name={fieldName}
            value={value}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            className={`w-full px-4 py-3 text-gray-700 bg-white border rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-200 ${
              showError ? 'border-red-300 animate-shake' : 'border-gray-200'
            }`}
            rows="4"
            placeholder={placeholder}
            aria-invalid={showError}
            aria-describedby={showError ? `${fieldName}-error` : undefined}
          />

          <div className="mt-2 flex justify-between items-center">
            <p className={`text-sm ${getCharacterCountColor()}`}>
              {value.length}/{maxLength} characters
            </p>
            {showError && (
              <p 
                id={`${fieldName}-error`}
                className="text-sm text-red-500 animate-fadeIn"
                role="alert"
              >
                {errorMessage}
              </p>
            )}
          </div>
        </div>

        {/* Updated Navigation Buttons */}
        <div className="flex justify-between items-center">
          <div>
            {showPrevious && (
              <button
                type="button"
                onClick={onPrevious}
                className="group px-6 py-3 text-sm font-semibold text-gray-700 bg-white border border-gray-200 rounded-lg hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500 transition-all duration-200 transform hover:-translate-x-0.5"
              >
                <FontAwesomeIcon 
                  icon={faArrowLeft} 
                  className="mr-2 transform group-hover:-translate-x-1 transition-transform duration-200" 
                />
                Previous
              </button>
            )}
          </div>
          <div>
            <button
              type="submit"
              className="group px-6 py-3 text-sm font-semibold text-white bg-blue-500 rounded-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 transition-all duration-200 transform hover:translate-x-0.5"
            >
              Next
              <FontAwesomeIcon 
                icon={faArrowRight} 
                className="ml-2 transform group-hover:translate-x-1 transition-transform duration-200" 
              />
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default GoalStepBase;