import React, { useState, useCallback } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faStickyNote, 
  faPlus, 
  faExclamationTriangle,
  faEdit,
  faTrash,
  faSave,
  faTimes,
  faPencilAlt,
  faShieldAlt
} from '@fortawesome/free-solid-svg-icons';

const MAX_NOTE_LENGTH = 280;
const WARN_NOTE_LENGTH = 200;
const MIN_NOTE_LENGTH = 3;

const Notes = ({ notes, onAddNote, onEditNote, onDeleteNote, isPremium }) => {
  const [newNoteContent, setNewNoteContent] = useState('');
  const [error, setError] = useState('');

  // Input sanitization
  const sanitizeInput = useCallback((input) => {
    return input
      .replace(/<[^>]*>/g, '')
      .replace(/[^\w\s.,!?()'\-"]/g, '')
      .trim();
  }, []);

  const validateNote = useCallback((content) => {
    const sanitizedContent = sanitizeInput(content);
    
    if (sanitizedContent.length < MIN_NOTE_LENGTH) {
      return 'Note must be at least 3 characters long';
    }
    if (sanitizedContent.length > MAX_NOTE_LENGTH) {
      return 'Note exceeds maximum length';
    }
    if (!/\S/.test(sanitizedContent)) {
      return 'Note cannot be empty or only whitespace';
    }
    return '';
  }, [sanitizeInput]);

  const handleNoteChange = (e) => {
    const newContent = e.target.value;
    const sanitizedContent = sanitizeInput(newContent);
    
    if (sanitizedContent.length <= MAX_NOTE_LENGTH) {
      setNewNoteContent(newContent);
      setError('');
    }
  };

  const handleAddNote = () => {
    const validationError = validateNote(newNoteContent);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const sanitizedContent = sanitizeInput(newNoteContent);
      
      if (!isPremium && notes.length >= 3) {
        // Trigger upgrade modal through parent
        onAddNote(null);
        return;
      }

      onAddNote(sanitizedContent);
      setNewNoteContent('');
      setError('');
    } catch (err) {
      setError('Failed to add note. Please try again.');
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      handleAddNote();
    }
  };

  const remainingChars = MAX_NOTE_LENGTH - newNoteContent.length;
  const isOverLimit = newNoteContent.length > MAX_NOTE_LENGTH;
  const isNearLimit = newNoteContent.length > WARN_NOTE_LENGTH;

  return (
    <div className="bg-yellow-100 rounded-xl shadow-md p-6 mb-8">
      <div className="flex justify-between items-center mb-8">
        <div>
          <h2 className="text-2xl font-bold text-gray-800 flex items-center mb-1">
            <FontAwesomeIcon icon={faStickyNote} className="text-yellow-500 mr-3" />
            Notes & Ideas
            {!isPremium && (
              <FontAwesomeIcon 
                icon={faShieldAlt} 
                className="ml-2 text-gray-400 text-sm" 
                title="Free plan restrictions apply"
              />
            )}
          </h2>
          <p className="text-sm text-gray-500">
            {isPremium ? 'Capture unlimited ideas and thoughts' : 'Free users can create up to 3 notes'}
          </p>
        </div>
        {!isPremium && notes.length > 0 && (
          <span className="px-4 py-2 bg-yellow-100 text-yellow-800 rounded-full text-sm font-medium">
            {3 - notes.length} note{3 - notes.length !== 1 ? 's' : ''} remaining
          </span>
        )}
      </div>

      {error && (
        <div className="mb-4 p-3 bg-red-50 border border-red-200 text-red-600 rounded-lg flex items-center">
          <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
          {error}
        </div>
      )}

      {/* New Note Input */}
      <div className="mb-8 bg-white rounded-xl shadow-sm p-4 hover:shadow-md transition-shadow duration-200">
        <div className="flex items-center mb-2">
          <FontAwesomeIcon icon={faPencilAlt} className="text-yellow-500 mr-2" />
          <span className="text-sm font-medium text-gray-700">New Note</span>
        </div>
        <textarea
          value={newNoteContent}
          onChange={handleNoteChange}
          onKeyPress={handleKeyPress}
          placeholder="Write your thoughts, ideas, or progress notes here..."
          className={`w-full px-4 py-3 rounded-lg border transition-all duration-200 ${
            isOverLimit 
              ? 'border-red-300 focus:ring-red-500'
              : isNearLimit
                ? 'border-yellow-300 focus:ring-yellow-500' 
                : 'border-gray-200 focus:ring-yellow-500'
          } focus:outline-none focus:ring-2 resize-none`}
          rows="3"
          maxLength={MAX_NOTE_LENGTH + 1}
        />
        <div className="flex justify-between items-center mt-3">
          <span className={`text-sm font-medium ${
            isOverLimit 
              ? 'text-red-500'
              : isNearLimit
                ? 'text-yellow-500' 
                : 'text-gray-400'
          }`}>
            {remainingChars} characters remaining
          </span>
          <button
            onClick={handleAddNote}
            disabled={!newNoteContent.trim() || isOverLimit}
            className={`px-6 py-2 rounded-lg font-medium flex items-center transition-all duration-300 ${
              !newNoteContent.trim() || isOverLimit
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-yellow-500 text-white hover:bg-yellow-600 transform hover:scale-105 shadow-sm hover:shadow'
            }`}
          >
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Note
          </button>
        </div>
      </div>

      {/* Notes Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 auto-rows-fr">
        {notes.map(note => (
          <StickyNote
            key={note.id}
            note={note}
            onEdit={onEditNote}
            onDelete={onDeleteNote}
            sanitizeInput={sanitizeInput}
            validateNote={validateNote}
          />
        ))}
      </div>
    </div>
  );
};

const StickyNote = ({ note, onEdit, onDelete, sanitizeInput, validateNote }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editContent, setEditContent] = useState(note.content);
  const [error, setError] = useState('');

  const handleEditChange = (e) => {
    const newContent = e.target.value;
    const sanitizedContent = sanitizeInput(newContent);
    
    if (sanitizedContent.length <= MAX_NOTE_LENGTH) {
      setEditContent(newContent);
      setError('');
    }
  };

  const handleSave = () => {
    const validationError = validateNote(editContent);
    if (validationError) {
      setError(validationError);
      return;
    }

    try {
      const sanitizedContent = sanitizeInput(editContent);
      onEdit(note.id, sanitizedContent);
      setIsEditing(false);
      setError('');
    } catch (err) {
      setError('Failed to save note. Please try again.');
    }
  };

  const handleCancel = () => {
    setEditContent(note.content);
    setIsEditing(false);
    setError('');
  };

  const remainingChars = MAX_NOTE_LENGTH - editContent.length;
  const isOverLimit = editContent.length > MAX_NOTE_LENGTH;
  const isNearLimit = editContent.length > WARN_NOTE_LENGTH;

  if (isEditing) {
    return (
      <div className="bg-yellow-200 rounded-xl p-5 shadow-md transition-all duration-300 border-2 border-yellow-300 h-full flex flex-col">
        {error && (
          <div className="mb-3 p-2 bg-red-50 border border-red-200 text-red-600 rounded-lg text-sm">
            {error}
          </div>
        )}
        <div className="flex-grow flex flex-col h-full">
          <textarea
            value={editContent}
            onChange={handleEditChange}
            className={`flex-grow w-full px-3 py-2 rounded-lg bg-white transition-all duration-200 resize-none min-h-[200px] ${
              isOverLimit
                ? 'border-red-300 focus:ring-red-500'
                : isNearLimit
                  ? 'border-yellow-300 focus:ring-yellow-500'
                  : 'border-yellow-300 focus:ring-yellow-500'
            } focus:ring-2 focus:border-transparent`}
            maxLength={MAX_NOTE_LENGTH + 1}
            autoFocus
          />
          <div className="flex justify-between items-center mt-4">
            <span className={`text-sm font-medium ${
              isOverLimit
                ? 'text-red-600'
                : isNearLimit
                  ? 'text-yellow-700'
                  : 'text-yellow-600'
            }`}>
              {remainingChars}
            </span>
            <div className="space-x-2">
              <button
                onClick={handleSave}
                disabled={!editContent.trim() || isOverLimit}
                className="px-3 py-1 rounded bg-green-500 text-white hover:bg-green-600 transition-colors duration-200 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                <FontAwesomeIcon icon={faSave} className="mr-1" />
                Save
              </button>
              <button
                onClick={handleCancel}
                className="px-3 py-1 rounded bg-gray-200 text-gray-700 hover:bg-gray-300 transition-colors duration-200"
              >
                <FontAwesomeIcon icon={faTimes} className="mr-1" />
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div 
      className="group bg-yellow-200 rounded-xl p-5 shadow-sm hover:shadow-md transition-all duration-300 border-2 border-transparent hover:border-yellow-300 h-full flex flex-col"
    >
      <div className="flex-grow overflow-y-auto">
        <p className="text-gray-700 whitespace-pre-wrap break-words">
          {note.content}
        </p>
      </div>
      <div className="flex justify-end space-x-2 mt-4">
        <button
          onClick={() => setIsEditing(true)}
          className="p-2 rounded-lg text-blue-500 hover:bg-yellow-300 transition-colors duration-200"
          title="Edit note"
        >
          <FontAwesomeIcon icon={faEdit} />
        </button>
        <button
          onClick={() => {
            onDelete(note.id);
          }}
          className="p-2 rounded-lg text-gray-500 hover:bg-yellow-300 transition-colors duration-200"
          title="Delete note"
        >
          <FontAwesomeIcon icon={faTrash} />
        </button>
      </div>
    </div>
  );
};

export default Notes;