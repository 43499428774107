import React from 'react';
import { Link } from 'react-router-dom';

const Hero = () => {
  return (
    <section className="bg-white px-8 py-8 md:pt-16 md:pb-14 rounded-lg grid grid-cols-1 md:grid-cols-2 gap-8 items-center">
      <div>
        <h2 className="text-4xl lg:text-5xl tracking-tight font-bold mb-4">
          Unlock Your Full Potential with <span className="text-blue-500">GoalMentorAI</span>.
        </h2>
        <p className="text-gray-600 mb-8 text-lg">
          GoalMentorAI is your 24/7 AI-powered strategist, transforming your ambitions into achievable daily tasks. With personalized plans, progress tracking, and motivational advice at every step, goal-setting becomes effortless—so you can focus on achieving.
        </p>
        <Link 
          to="/signup" 
          className="bg-blue-500 text-white px-6 py-3 rounded-full text-lg font-semibold hover:bg-blue-600 inline-block transform hover:scale-105 transition-all duration-300 shadow-md hover:shadow-lg"
        >
          Create Your Free Goal Plan
        </Link>
      </div>
      <div className="flex justify-center">
        <img 
          src="/images/hero.png" 
          alt="Person achieving goals" 
          className="rounded-lg transition-all duration-300 transform"
        />
      </div>
    </section>
  );
};

export default Hero;