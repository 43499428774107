import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
  faComments, 
  faExclamationTriangle, 
  faSpinner, 
  faExternalLinkAlt, 
} from '@fortawesome/free-solid-svg-icons';

const AIMentorChat = ({
  mentorInput,
  setMentorInput,
  mentorResponse,
  isMentorResponding,
  onSendMessage,
  isPremium,
  remainingInteractions,
  maxInputLength = 280
}) => {
  // Input sanitization
  const sanitizeInput = useCallback((input) => {
    return input
      .replace(/<[^>]*>/g, '')
      .replace(/[^\w\s.,!?()'-]/g, '')
      .slice(0, maxInputLength);
  }, [maxInputLength]);

  // URL validation for resources
  const isValidUrl = (url) => {
    try {
      const newUrl = new URL(url);
      return newUrl.protocol === 'https:' || newUrl.protocol === 'http:';
    } catch {
      return false;
    }
  };

  const handleInputChange = (e) => {
    const sanitizedValue = sanitizeInput(e.target.value);
    setMentorInput(sanitizedValue);
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (canSubmit()) {
        onSendMessage();
      }
    }
  };

  const canSubmit = () => {
    const trimmedInput = mentorInput.trim();
    return (
      trimmedInput.length > 0 &&
      trimmedInput.length <= maxInputLength &&
      !isMentorResponding
    );
  };

  const remainingChars = maxInputLength - mentorInput.length;
  const isOverLimit = mentorInput.length > maxInputLength;
  const warningThreshold = maxInputLength * 0.7;

  return (
    <div className="bg-blue-100 rounded-lg shadow-md p-6 mb-8">
      <h2 className="text-2xl font-bold text-gray-800 flex items-center mb-6">
        <FontAwesomeIcon icon={faComments} className="text-blue-500 mr-3" />
        Talk to Your Mentor
      </h2>

      <div className="space-y-4">
        <div className="relative">
          <textarea
            value={mentorInput}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
            placeholder="Ask your mentor for advice, resources, or help with a specific part of your goal..."
            className={`w-full px-4 py-3 text-gray-700 border rounded-lg focus:ring-2 focus:border-transparent transition-all duration-200 ${
              isOverLimit 
                ? 'border-red-300 focus:ring-red-500' 
                : mentorInput.length > warningThreshold
                ? 'border-yellow-300 focus:ring-yellow-500'
                : 'border-gray-200 focus:ring-blue-500'
            }`}
            rows="4"
            maxLength={maxInputLength + 1}
            aria-label="Mentor chat input"
          />
          <div className="absolute bottom-3 right-3">
            <span className={`text-sm font-medium ${
              isOverLimit 
                ? 'text-red-500' 
                : mentorInput.length > warningThreshold
                ? 'text-yellow-500'
                : 'text-gray-400'
            }`}>
              {remainingChars}
            </span>
          </div>
        </div>

        <div className="flex justify-end items-center">
          {!isPremium && (
            <div className="flex items-center space-x-2 mr-auto">
              <span className="text-sm text-gray-500">
                {remainingInteractions} mentor interactions left today
              </span>
            </div>
          )}
          <button
            onClick={onSendMessage}
            disabled={!canSubmit()}
            className={`px-6 py-3 rounded-lg font-medium flex items-center transition-all duration-300 ${
              !canSubmit()
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-blue-500 text-white hover:bg-blue-600 transform hover:scale-105'
            }`}
            aria-label={isMentorResponding ? "Mentor is thinking" : "Send message to mentor"}
          >
            {isMentorResponding ? (
              <>
                <FontAwesomeIcon icon={faSpinner} className="animate-spin mr-2" />
                Thinking...
              </>
            ) : (
              <>
                <img 
                  src="/images/logo-white.png" 
                  alt="" 
                  className="w-5 h-5 mr-2"
                  aria-hidden="true"
                />
                Ask Mentor
              </>
            )}
          </button>
        </div>

        {mentorResponse && (
          <div className={`mt-6 p-6 rounded-lg ${
            mentorResponse.error ? 'bg-red-50' : 'bg-blue-50'
          }`}>
            {mentorResponse.error ? (
              <div className="text-red-600" role="alert">
                <FontAwesomeIcon icon={faExclamationTriangle} className="mr-2" />
                {mentorResponse.message}
              </div>
            ) : (
              <div className="space-y-4">
                {mentorResponse.advice && (
                  <p className="text-gray-700">{mentorResponse.advice}</p>
                )}

                {mentorResponse.actionSteps?.length > 0 && (
                  <div>
                    <h4 className="font-semibold text-gray-800 mb-2">Suggested Steps:</h4>
                    <ul className="list-disc pl-5 space-y-2">
                      {mentorResponse.actionSteps.map((step, index) => (
                        <li key={index} className="text-gray-600">
                          {sanitizeInput(step)}
                        </li>
                      ))}
                    </ul>
                  </div>
                )}

                {mentorResponse.resources?.length > 0 && (
                  <div className="bg-white bg-opacity-50 rounded-lg p-4">
                    <h4 className="font-semibold text-gray-800 mb-2">Helpful Resources:</h4>
                    <ul className="space-y-2">
                      {mentorResponse.resources
                        .filter(resource => isValidUrl(resource.url))
                        .map((resource, index) => (
                          <li key={index}>
                            <a 
                              href={resource.url}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:text-blue-800 flex items-center"
                            >
                              {sanitizeInput(resource.title)}
                              <FontAwesomeIcon 
                                icon={faExternalLinkAlt} 
                                className="ml-1 text-xs" 
                                aria-label="Opens in new tab"
                              />
                            </a>
                            {resource.description && (
                              <p className="text-sm text-gray-600 mt-1">
                                {sanitizeInput(resource.description)}
                              </p>
                            )}
                          </li>
                        ))}
                    </ul>
                  </div>
                )}

                {mentorResponse.motivationalQuote && (
                  <div className="italic text-gray-600 border-l-4 border-blue-500 pl-4 mt-4">
                    {sanitizeInput(mentorResponse.motivationalQuote)}
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

AIMentorChat.propTypes = {
  mentorInput: PropTypes.string.isRequired,
  setMentorInput: PropTypes.func.isRequired,
  mentorResponse: PropTypes.object,
  isMentorResponding: PropTypes.bool.isRequired,
  onSendMessage: PropTypes.func.isRequired,
  isPremium: PropTypes.bool.isRequired,
  remainingInteractions: PropTypes.number.isRequired,
  maxInputLength: PropTypes.number
};

export default AIMentorChat;