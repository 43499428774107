import React, { useEffect } from 'react';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import Signup from '../components/Signup';
import { useAuth } from '../AuthContext';
import { trackEvent } from '../utils/analytics'; 

const SignUpPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const isPremiumFlow = location.search.includes('premium=true');

  useEffect(() => {
    if (user) {
      const redirectPath = isPremiumFlow ? '/payment' : '/dashboard';
      navigate(redirectPath);
    } else {
      window.scrollTo(0, 0);
      trackEvent('page_view', {
        page_title: isPremiumFlow ? 'Premium Signup' : 'Signup',
        page_location: window.location.href,
        page_path: window.location.pathname,
        is_premium_flow: isPremiumFlow
      });
    }
  }, [user, navigate, isPremiumFlow]);

  return (
    <div className="min-h-screen bg-gray-50 flex flex-col justify-start pt-20 pb-28 px-4 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <Link 
          to="/" 
          className="flex items-center justify-center mb-8 transform transition-transform duration-300 hover:scale-105"
        >
          <img src="/images/logo-blorange.png" alt="GoalMentorAI Logo" className="h-12 mr-3" />
          <h1 className="text-3xl font-bold tracking-tight text-blue-500">GoalMentorAI</h1>
        </Link>
        <h2 className="text-center text-2xl font-bold text-gray-900 mb-2">
          {isPremiumFlow ? 'Create your premium account' : 'Create your account'}
        </h2>
        <p className="text-center text-lg text-gray-600 max-w-sm mx-auto mb-8">
          {isPremiumFlow 
            ? 'Get started with premium features today!'
            : 'Start your goal achievement journey today!'}
        </p>
      </div>

      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow-lg sm:rounded-lg sm:px-10">
          <Signup isPremiumFlow={isPremiumFlow} />
          <div className="mt-6">
            <p className="text-center text-gray-600">
              Already have an account?{' '}
              <Link 
                to={`/login${isPremiumFlow ? '?premium=true' : ''}`}
                className="font-semibold text-blue-500 hover:text-blue-600 transition-colors duration-300"
              >
                Sign in
              </Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUpPage;