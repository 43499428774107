import React from 'react';

const UpgradeModal = ({ isOpen, onClose, onUpgrade, triggerFeature, isLoading = false }) => {
  const getModalContent = () => {
    switch (triggerFeature) {
      case 'notes':
        return {
          emoji: '📝',
          title: 'Unlock Unlimited Notes',
          description: "You've reached the maximum number of notes for free users. Upgrade to premium for unlimited notes and more features!",
          features: [
            'Unlimited notes and ideas',
            'Advanced organization tools',
            'Cloud sync and backup',
            'Priority support',
            'Cross-device synchronization',
          ]
        };
      case 'mentor':
        return {
          emoji: '🤖',
          title: 'Unlimited Mentor Access',
          description: "You've reached today's limit for mentor interactions. Upgrade to premium for unlimited access!",
          features: [
            'Unlimited AI mentor conversations',
            'Advanced goal insights',
            'Personalized recommendations',
            'Priority support',
            '24/7 mentor availability'
          ]
        };
      case 'check-ins':
        return {
          emoji: '🔔',
          title: 'Unlock Goal Check-ins',
          description: "Stay on track with automated check-ins and progress tracking. Upgrade to premium to enable this feature!",
          features: [
            'Daily or weekly check-ins',
            'Personalized reminders',
            'Progress analytics',
            'Custom check-in schedules',
            'Goal progress reports'
          ]
        };
      case 'analytics':
        return {
          emoji: '📊',
          title: 'Advanced Analytics',
          description: "Get deeper insights into your goal progress with premium analytics!",
          features: [
            'Detailed progress tracking',
            'Custom progress reports',
            'Goal trend analysis',
            'Performance metrics',
            'Data visualization tools'
          ]
        };
      default:
        return {
          emoji: '⭐',
          title: 'Upgrade to Premium',
          description: "Take your goal achievement to the next level with premium features!",
          features: [
            'Unlimited notes and mentor access',
            'Advanced goal tracking',
            'Priority support',
            'Premium analytics',
            'Exclusive resources'
          ]
        };
    }
  };

  if (!isOpen) return null;

  const content = getModalContent();

  return (
    <div className="fixed z-50 inset-0 overflow-y-auto" aria-labelledby="modal-title" role="dialog" aria-modal="true">
      <div className="flex items-center justify-center min-h-screen px-4 pt-4 pb-20 text-center sm:block sm:p-0">
        {/* Background overlay */}
        <div 
          className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" 
          aria-hidden="true"
          onClick={onClose}
        />

        {/* Modal positioning */}
        <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>

        {/* Modal content */}
        <div className="relative inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          {/* Close button */}
          <button
            onClick={onClose}
            className="absolute top-4 right-4 text-gray-400 hover:text-gray-500 focus:outline-none"
            disabled={isLoading}
          >
            <span className="sr-only">Close</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div className="sm:flex sm:items-start">
              {/* Icon */}
              <div className="mx-auto flex-shrink-0 flex items-center justify-center h-12 w-12 rounded-full bg-blue-100 sm:mx-0 sm:h-14 sm:w-14">
                <span className="text-2xl sm:text-3xl" role="img" aria-label="feature">
                  {content.emoji}
                </span>
              </div>

              {/* Content */}
              <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left flex-1">
                <h3 className="text-lg sm:text-xl leading-6 font-bold text-gray-900">
                  {content.title}
                </h3>
                <div className="mt-2">
                  <p className="text-sm text-gray-500">
                    {content.description}
                  </p>
                  
                  {/* Features list */}
                  <ul className="mt-4 space-y-3">
                    {content.features.map((feature, index) => (
                      <li key={index} className="flex items-center text-sm text-gray-600">
                        <span className="flex-shrink-0 w-5 h-5 text-blue-500 mr-2">✓</span>
                        {feature}
                      </li>
                    ))}
                  </ul>

                </div>
              </div>
            </div>
          </div>

          {/* Action buttons */}
          <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button
              type="button"
              onClick={onUpgrade}
              disabled={isLoading}
              className={`w-full inline-flex justify-center rounded-lg border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white 
                ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-blue-700'} 
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm 
                transition-all duration-300 transform hover:scale-105`}
            >
              {isLoading ? (
                <span className="flex items-center">
                  <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" fill="none" viewBox="0 0 24 24">
                    <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4" />
                    <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z" />
                  </svg>
                  Processing...
                </span>
              ) : (
                'Upgrade Now'
              )}
            </button>
            <button
              type="button"
              onClick={onClose}
              disabled={isLoading}
              className={`mt-3 w-full inline-flex justify-center rounded-lg border border-gray-300 shadow-sm px-4 py-2 
                bg-white text-base font-medium text-gray-700 
                ${isLoading ? 'opacity-75 cursor-not-allowed' : 'hover:bg-gray-50'}
                focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 
                sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm transition-colors duration-200`}
            >
              Maybe Later
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradeModal;