import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faChevronUp } from '@fortawesome/free-solid-svg-icons';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="border-b border-gray-200 transition-colors duration-300">
      <button
        className="flex justify-between items-center w-full py-4 text-left focus:outline-none"
        onClick={() => setIsOpen(!isOpen)}
      >
        <span className="text-lg font-semibold text-gray-800">{question}</span>
        <FontAwesomeIcon
          icon={isOpen ? faChevronUp : faChevronDown}
          className={`text-blue-500 transition-transform duration-300 ${isOpen ? 'rotate-180' : ''}`}
        />
      </button>
      <div className={`overflow-hidden transition-all duration-300 ${isOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'}`}>
        <p className="pb-4 text-gray-600 text-lg">{answer}</p>
      </div>
    </div>
  );
};

const FAQ = () => {
  const faqs = [
    {
      question: "What is GoalMentorAI?",
      answer: "GoalMentorAI is an AI-powered goal-setting and achievement platform. It helps you set SMART goals, creates personalized action plans, and provides daily guidance to help you achieve your dreams."
    },
    {
      question: "What's the purpose behind GoalMentorAI?",
      answer: "We built GoalMentorAI to help you unlock your full potential by making goal-setting simple, personalized, and stress-free. We're passionate about helping you succeed by transforming goals into actionable steps, so you can focus on what truly matters—taking action and achieving."
    },
    {
      question: "Why choose GoalMentorAI?",
      answer: "GoalMentorAI streamlines the goal-setting process, allowing you to focus on execution rather than planning. Our AI handles the complex task of breaking down your objectives into actionable steps, so you can channel your energy into actually achieving your goals. It's like having a personal strategist and motivator, working 24/7 to keep you on track and moving forward."
    },
    {
      question: "What kind of goals does GoalMentorAI help with?",
      answer: "GoalMentorAI can help with all types of goals, whether personal, professional, or creative. From fitness and career advancement to learning new skills, saving money, improving relationships, or even planning trips—GoalMentorAI provides tailored support to help you succeed in any area of your life."
    },
    {
      question: "How does the AI create my goal plan?",
      answer: "Our advanced AI analyzes your specific goal, timeline, and available time. It then generates a detailed, step-by-step plan tailored to your needs, including daily tasks, milestones, and resource recommendations."
    },
    {
      question: "How do I track my progress?",
      answer: "GoalMentorAI allows you to easily track your progress on each of your goals within your goal management page. The AI adjusts your plan based on your achievements, ensuring your tasks remain aligned with your overall goal."
    },
    {
      question: "Is my data safe and private?",
      answer: "Yes, we take data privacy very seriously. All your personal information and goal details are encrypted and securely stored. We never share your data with third parties."
    },
    {
      question: "What if my goals change?",
      answer: "Don't worry! You can create multiple goals at any time, and GoalMentorAI will create a plan to fit your new ambitions, ensuring you're always on the right path."
    },
    {
      question: "Can I use GoalMentorAI on my phone?",
      answer: "Yup! Easily access your GoalMentorAI dashboard from your favorite browser. Our official iOS and Android mobile apps are in development!"
    },
    {
      question: "I need something more, can you customize my plan?",
      answer: "Absolutely! Please contact us to learn more about our custom and corporate plans!"
    },
    {
      question: "How much does GoalMentorAI cost?",
      answer: "GoalMentorAI is available for just $9.99 per month. Invest in your dreams for less than what you pay for Netflix!"
    }
  ];

  return (
    <section id="faq" className="py-16 bg-gray-50">
      <div className="container mx-auto px-4 max-w-7xl">
        <h2 className="text-3xl lg:text-4xl tracking-tight font-bold text-center mb-12">
          Frequently Asked <span className="text-blue-500">Questions</span>
        </h2>
        <div className="max-w-3xl mx-auto bg-white rounded-lg shadow-md p-6 hover:shadow-lg transition-shadow duration-300">
          {faqs.map((faq, index) => (
            <FAQItem key={index} question={faq.question} answer={faq.answer} />
          ))}
        </div>
      </div>
    </section>
  );
};

export default FAQ;