import React from 'react';
import { Link } from 'react-router-dom';

const GoalCategory = ({ emoji, example, desktopPosition, mobilePosition, rotate }) => (
  <div 
    className="absolute transform transition-all duration-300 hover:scale-110 bg-white border border-gray-100 rounded-full shadow-md hover:shadow-lg px-4 py-2 flex items-center md:hidden"
    style={{
      top: `${mobilePosition.top}%`,
      left: `${mobilePosition.left}%`,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    }}
  >
    <span className="text-2xl mr-2" role="img" aria-label={example}>
      {emoji}
    </span>
    <span className="text-base font-medium text-gray-700 whitespace-nowrap">{example}</span>
  </div>
);

const GoalCategoryDesktop = ({ emoji, example, desktopPosition, mobilePosition, rotate }) => (
  <div 
    className="absolute transform transition-all duration-300 hover:scale-110 bg-white border border-gray-100 rounded-full shadow-md hover:shadow-lg px-4 py-2 flex items-center hidden md:flex"
    style={{
      top: `${desktopPosition.top}%`,
      left: `${desktopPosition.left}%`,
      transform: `translate(-50%, -50%) rotate(${rotate}deg)`,
    }}
  >
    <span className="text-2xl mr-2" role="img" aria-label={example}>
      {emoji}
    </span>
    <span className="text-base font-medium text-gray-700 whitespace-nowrap">{example}</span>
  </div>
);

const GoalCategories = () => {
  const categories = [
    { 
      emoji: '📚', 
      example: "Learn a language", 
      desktopPosition: { top: 18, left: 50 },
      mobilePosition: { top: 5, left: 46 },
      rotate: 5 
    },
    { 
      emoji: '💼', 
      example: "Get promoted", 
      desktopPosition: { top: 30, left: 78 },
      mobilePosition: { top: 12, left: 85 },
      rotate: 10 
    },
    { 
      emoji: '💰', 
      example: "Save for retirement", 
      desktopPosition: { top: 70, left: 85 },
      mobilePosition: { top: 26, left: 73 },
      rotate: 10 
    },
    { 
      emoji: '🏋️‍♀️', 
      example: "Start a fitness plan", 
      desktopPosition: { top: 80, left: 53 },
      mobilePosition: { top: 15, left: 20 },
      rotate: -5 
    },
    { 
      emoji: '🧘‍♀️', 
      example: "Start meditating", 
      desktopPosition: { top: 60, left: 25 },
      mobilePosition: { top: 27, left: 33 },
      rotate: 12 
    },
    { 
      emoji: '🌱', 
      example: "Start a garden", 
      desktopPosition: { top: 35, left: 20 },
      mobilePosition: { top: 37, left: 95 },
      rotate: -8 
    },
    { 
      emoji: '🏠', 
      example: "Buy first home", 
      desktopPosition: { top: 50, left: 12 },
      mobilePosition: { top: 35, left: 5 },
      rotate: -15 
    },
    { 
      emoji: '✈️', 
      example: "Plan your next trip", 
      desktopPosition: { top: 43, left: 85 },
      mobilePosition: { top: 37, left: 55 },
      rotate: -12 
    },
    { 
      emoji: '🎨', 
      example: "Learn to paint", 
      desktopPosition: { top: 15, left: 68 },
      mobilePosition: { top: 50, left: 95 },
      rotate: 20 
    },
    { 
      emoji: '🎸', 
      example: "Play the guitar", 
      desktopPosition: { top: 90, left: 38 },
      mobilePosition: { top: 48, left: 9 },
      rotate: 15 
    },
    { 
      emoji: '👨‍👩‍👧‍👦', 
      example: "Plan for children", 
      desktopPosition: { top: 28, left: 37 },
      mobilePosition: { top: 60, left: 35 },
      rotate: 7 
    },
    { 
      emoji: '🌍', 
      example: "Travel the world", 
      desktopPosition: { top: 80, left: 76 },
      mobilePosition: { top: 62, left: 80 },
      rotate: 14 
    },
    { 
      emoji: '💍', 
      example: "Plan your wedding", 
      desktopPosition: { top: 10, left: 80 },
      mobilePosition: { top: 70, left: 12 },
      rotate: 18 
    },
    { 
      emoji: '🚗', 
      example: "Build a racecar", 
      desktopPosition: { top: 80, left: 20 },
      mobilePosition: { top: 80, left: 90 },
      rotate: -20 
    },
    { 
      emoji: '🏆', 
      example: "Run a marathon", 
      desktopPosition: { top: 55, left: 90 },
      mobilePosition: { top: 70, left: 60 },
      rotate: -5 
    },
    { 
      emoji: '👨‍🍳', 
      example: "Become a chef", 
      desktopPosition: { top: 25, left: 10 },
      mobilePosition: { top: 90, left: 40 },
      rotate: -10 
    },
    { 
      emoji: '🧠', 
      example: "Learn a new skill", 
      desktopPosition: { top: 70, left: 45 },
      mobilePosition: { top: 80, left: 55 },
      rotate: -3 
    },
    { 
      emoji: '🎵', 
      example: "Produce music", 
      desktopPosition: { top: 10, left: 30 },
      mobilePosition: { top: 84, left: 16 },
      rotate: -22 
    },
    { 
      emoji: '💻', 
      example: "Learn to code", 
      desktopPosition: { top: 30, left: 59 },
      mobilePosition: { top: 92, left: 85 },
      rotate: -6 
    },
    { 
      emoji: '📂', 
      example: "Get organized", 
      desktopPosition: { top: 70, left: 8 },
      mobilePosition: { top: 17, left: 55 },
      rotate: -8 
    },
    { 
      emoji: '📖', 
      example: "Write a book", 
      desktopPosition: { top: 65, left: 65 },
      mobilePosition: { top: 5, left: 12 },
      rotate: 8 
    },
    { 
      emoji: '🚀', 
      example: "Start a business", 
      desktopPosition: { top: 0, left: 50 },
      mobilePosition: { top: 0, left: 88 },
      rotate: -4 
    }
  ];
  
  return (
    <section id="categories" className="py-16 md:py-8 sm:py-4 bg-white w-full sm:w-auto overflow-hidden">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="relative" style={{ height: '600px' }}>
          <h2 className="absolute tracking-tight top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl md:text-4xl lg:text-5xl font-bold text-center text-gray-800 z-10 p-4 rounded-lg bg-transparent">
            Works with <span className="text-blue-500">all of<br />your goals</span>
          </h2>
          {categories.map((category, index) => (
            <Link to="/signup" key={index}>
              <GoalCategory 
                emoji={category.emoji} 
                example={category.example}
                desktopPosition={category.desktopPosition}
                mobilePosition={category.mobilePosition}
                rotate={category.rotate}
              />
              <GoalCategoryDesktop 
                emoji={category.emoji} 
                example={category.example}
                desktopPosition={category.desktopPosition}
                mobilePosition={category.mobilePosition}
                rotate={category.rotate}
              />
            </Link>
          ))}
        </div>
      </div>
      <style jsx>{`
        @media (max-width: 768px) {
          #categories .relative {
            height: 800px !important;
          }
        }
      `}</style>
    </section>
  );
};

export default GoalCategories;