import React, { useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../AuthContext';

const Header = () => {
  const { isAuthenticated, logout } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const scrollToSection = (sectionId) => {
    setIsMenuOpen(false);
    if (location.pathname !== '/') {
      navigate('/', { state: { scrollTo: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        const yOffset = -80;
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
        window.scrollTo({ top: y, behavior: 'smooth' });
      }
    }
  };

  return (
    <header className="bg-gradient-to-r from-blue-500 to-blue-600 py-4 shadow-lg w-full z-50">
      <div className="container mx-auto px-4 max-w-7xl">
        <div className="flex justify-between items-center">
          <Link 
            to={isAuthenticated ? "/dashboard" : "/"} 
            className="flex items-center transform transition-transform duration-300 hover:scale-105"
          >
            <img 
              src="/images/logo-white.png" 
              alt="GoalMentorAI Logo" 
              className="h-10 mr-3"
            />
            <h1 className="text-2xl text-white font-bold tracking-tight">GoalMentorAI</h1>
          </Link>
          
          {/* Hamburger menu icon */}
          <button 
            className="lg:hidden text-white hover:bg-blue-600 p-2 rounded-lg transition-colors duration-300 focus:outline-none"
            onClick={toggleMenu}
          >
            <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>

          {/* Desktop menu */}
          <nav className="hidden lg:flex items-center space-x-6">
            {!isAuthenticated && (
              <>
                <button 
                  onClick={() => scrollToSection('how-it-works')} 
                  className="text-white hover:text-blue-100 transition-colors duration-300"
                >
                  How It Works
                </button>
                <button 
                  onClick={() => scrollToSection('pricing')} 
                  className="text-white hover:text-blue-100 transition-colors duration-300"
                >
                  Pricing
                </button>
                <button 
                  onClick={() => scrollToSection('faq')} 
                  className="text-white hover:text-blue-100 transition-colors duration-300"
                >
                  FAQ
                </button>
              </>
            )}
            {isAuthenticated ? (
              <>
                <Link to="/dashboard" className="text-white hover:text-blue-100 transition-colors duration-300">
                  Dashboard
                </Link>
                <button
                  onClick={handleLogout}
                  className="bg-white text-blue-500 px-6 py-2 rounded-full font-semibold hover:bg-blue-50 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" className="text-white hover:text-blue-100 transition-colors duration-300">
                  Log In
                </Link>
                <Link
                  to="/signup"
                  className="bg-white text-blue-500 px-6 py-2 rounded-full font-semibold hover:bg-blue-50 transition-all duration-300 transform hover:scale-105 shadow-md hover:shadow-lg"
                >
                  Sign Up
                </Link>
              </>
            )}
          </nav>
        </div>

        {/* Mobile menu */}
        <div className={`lg:hidden transition-all duration-300 ${
          isMenuOpen ? 'max-h-96 opacity-100' : 'max-h-0 opacity-0'
        } overflow-hidden`}>
          <nav className="mt-4 bg-blue-600 rounded-lg p-4">
            {!isAuthenticated && (
              <>
                <button onClick={() => scrollToSection('how-it-works')} className="block text-white py-3 px-4 w-full text-left hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  How It Works
                </button>
                <button onClick={() => scrollToSection('pricing')} className="block text-white py-3 px-4 w-full text-left hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  Pricing
                </button>
                <button onClick={() => scrollToSection('faq')} className="block text-white py-3 px-4 w-full text-left hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  FAQ
                </button>
              </>
            )}
            {isAuthenticated ? (
              <>
                <Link to="/dashboard" onClick={toggleMenu} className="block text-white py-3 px-4 hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  Dashboard
                </Link>
                <button
                  onClick={() => {
                    toggleMenu();
                    handleLogout();
                  }}
                  className="block w-full text-left text-white py-3 px-4 hover:bg-blue-700 rounded-lg transition-colors duration-300"
                >
                  Logout
                </button>
              </>
            ) : (
              <>
                <Link to="/login" onClick={toggleMenu} className="block text-white py-3 px-4 hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  Log In
                </Link>
                <Link to="/signup" onClick={toggleMenu} className="block text-white py-3 px-4 hover:bg-blue-700 rounded-lg transition-colors duration-300">
                  Sign Up
                </Link>
              </>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
};

export default Header;